import BaseZeroField from './BaseZeroField.js';


class LabelZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);

    this.mode = fieldData.mode === undefined ? this.mode : fieldData.mode; // ['simple', 'wrap', 'html', 'rhtml']
    this.maxLinesCount = fieldData.lines_count === undefined ? this.maxLinesCount : fieldData.lines_count;
  }

  setValue(newVal) {
    if (this.value !== undefined) return;
    super.setValue(newVal);
  }

  packToFormData(container, prefix='') {
    return;
  }
}

export default LabelZeroField;
