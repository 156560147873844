<template>
  <input v-show="!this.baseField.isHidden" v-if="!this.baseField.isBig" class="text_input" ref="ruler_input" @input="this.updateInput" @keydown="this.updateInput"/>
  <textarea v-show="!this.baseField.isHidden" v-else :rows="this.baseField.rows == null ? 3 : this.baseField.rows" class="big_text_input" ref="ruler_input" @input="this.updateInput" @keydown="this.updateInput"></textarea>
</template>

<script>

export default {
  components: {

  },
  data: () => ({

  }),
  props: [
    'baseField',
  ],
  computed: {

  },
  mounted() {
    this.baseField.addChangeListener(this.setValue);
    setTimeout(() => {
      this.setValue();
      this.updateInput();
    }, 1);
  },
  unmounted() {
    this.baseField.removeChangeListener(this.setValue);
  },
  methods: {
    updateInput() {
      if (this.$refs.ruler_input == null) return;

      const newVal = this.$refs.ruler_input.value;

      const curError = this.baseField.validateValue(newVal);

      if (curError != null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.baseField.value = null;
        // eslint-disable-next-line vue/no-mutating-props
        this.baseField.errorText = curError;
        return;
      }

      this.baseField.setValue(newVal);

      // this.setValue();
    },
    setValue() {
      if (this.$refs.ruler_input == null) return;
      this.$refs.ruler_input.value = this.baseField.value;
    },
    setFocus(val) {
      if (!val) return;
      if (this.$refs.ruler_input == null) return;
      this.$refs.ruler_input.focus();
    },
  },
};
</script>
