import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/auth/logout/';

function logoutUser(handler) {
  const ans = fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
  }).then(function(result) {
    handler(result);
  });

  // const logoutUserDataSource=Object.create(dataSource(ROOT_URL + REQUEST_LINK, handler)); // родительский объект

  // // method - метод запроса
  // // data - тело POST запроса
  // // headers  - загловки POST запроса
  // // contentRequestType - тип запроса text или json (по умолчанию)

  // // метод запроса
  // logoutUserDataSource.method = 'POST';
  // logoutUserDataSource.data='';
  // // content type запроса : json по умолчанию или еу
  // logoutUserDataSource.contentRequestType='text';
  return ans;
}

export default logoutUser;
