<template>
  <LabelZeroField :baseField="this.baseField" :withoutTitle="true"/>
</template>

<script>
import LabelZeroField from '../LabelZeroField.vue';

export default {
  components: {
    LabelZeroField,
  },
  props: [
    'baseField',
    'withoutTitle',
  ],
};
</script>
