import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
// import store from '@/store';

const REQUEST_LINK = '/iapi_report/get_preview_report_file_list/?path=';

function getPreviewReportFileList(path, dsUid, handler) {
  // store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + path + '&ds_uid=' + dsUid, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.json());
      return;
    }

    if (response.status == 401) {
      const dsData = await response.json();

      const authResult = await window.openExtAuthWindow(dsData.uid, dsData.view);
      if (authResult == 'ok') {
        getPreviewReportFileList(path, handler);
        return;
      }

      handler({
        _error: true,
        status: response.status,
        text: 'Ошибка доступа к источнику. Неверный логин или пароль.',
      });
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка формирования списка файлов. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    console.log('errData', errData);
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default getPreviewReportFileList;
