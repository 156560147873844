<template>
  <div class="short-bottom-panel-frame"
       v-show="this.getShortSiteStruct().length > 0">
      <div v-for="(item, key) in this.getShortSiteStruct()" :key="key" class="bottom-button">
        <ButtonComponent
            :iconUrl="item.icon_url"
            :label="item.name"
            :iconPosition="'top'"
            :elementType="'hover'"
            :iconSize="'20'"
            :elementStatus="this.buttonIsActive(item) ? 'selected' : undefined"
            :labelType="'caption/small/regular 10'"
            :href="item.fullUrl"
        />
      </div>

      <div v-if="this.moreThanShort" class="bottom-button">
        <ButtonComponent
            iconUrl="/icons/system/more.svg"
            :label="'Ещё'"
            :iconPosition="'top'"
            :elementType="'hover'"
            :iconSize="'20'"
            :labelType="'caption/small/regular 10'"
            @click_to_button="this.addonOpen = true"
        />
      </div>
  </div>
  <div v-if="this.addonOpen"
       @click="this.closeAddon()"
       class="dark-background">
      <div class="addonContainer" @click="this.addonClick()">
        <div v-for="(item, key) in this.getAddonSiteStruct" :key="key" class="bottom-button">
        <ButtonComponent
        :iconUrl="item.icon_url"
            :label="item.name"
            :iconPosition="'top'"
            :elementType="'hover'"
            :iconSize="'20'"
            :elementStatus="this.buttonIsActive(item) ? 'selected' : undefined"
            :labelType="'caption/small/regular 10'"
            :href="item.fullUrl"
            @click_to_button="this.addonOpen = false"
        />
      </div>
      </div>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  name: 'BottomPanel',
  components: {
    ButtonComponent,
  },
  data: ()=> ({
    maxButtonCount: 5,
    moreThanShort: false,
    addonOpen: false,
    NoAddonClose: false,
  }),
  computed: {
    getAddonSiteStruct() {
      const ans = this.getSiteInfo();
      const addon= [];
      for (let i = this.maxButtonCount-1; i < ans.length; i+=1) {
        addon.push(ans[i]);
      }
      return addon;
    },
    getFullSiteLines() {
      const workArray = this.getSiteInfo();
      const ans = [];

      for (let i = 0; i < workArray.length; i += this.maxButtonCount) {
        ans.push(workArray.slice(i, i + this.maxButtonCount));
        // do whatever
      }

      return ans;
    },
    get_current_page_info() {
      return this.$store.state.current_page_info;
    },
  },
  methods: {
    // normaliseItemLink(link) {
    //   if (!String(link).startsWith('http') && !String(link).startsWith('/') && !String(link).startsWith('\\')) {
    //     return '/' + String(link);
    //   }
    //   return String(link);
    // },
    buttonIsActive(button) {
      // console.log('this.get_current_page_info', this.get_current_page_info, button);
      return this.get_current_page_info && button.uid === this.get_current_page_info.uid;
    },
    getShortSiteStruct() {
      const ans = this.getSiteInfo();
      if (ans.length > this.maxButtonCount) {
        this.moreThanShort = true;
      }

      ans.length = Math.min(ans.length, this.moreThanShort ? this.maxButtonCount-1 : this.maxButtonCount);
      return ans;
    },
    closeAddon() {
      if (this.NoAddonClose) {
        this.NoAddonClose = false;
        return;
      }
      this.addonOpen = false;
    },
    addonClick() {
      this.NoAddonClose = true;
    },
    getSiteInfo() {
      return this.$store.state.fast_panel_struct.map((item) => item);
    },
    burgerClick() {
      this.$emit(
          'left-menu',
          'on',
      );
    },
  },
};


</script>

<style lang="less">
.bottom-button {
  margin: auto;
  padding: 2px 7px 3px 7px;

  p{
    text-align: center;
    max-height: 24px;

    .label-inner {
      max-height: 24px;

      & > * {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 24px;
        display: -webkit-box;
        overflow-wrap: anywhere;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.short-bottom-panel-frame{
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(5, 20vw);
}

// .full-bottom-panel-frame {
//   z-index: 102;
//   width: 100vw;
//   display: grid;
//   grid-template-columns: repeat(5, 20vw);
//   background-color: @content-background-color;

//   table {
//     width: 100%;
//   }
// }

.dark-background {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: @fade-content-background-color;
  z-index: 101;
  backdrop-filter: blur(4px);
  .addonContainer{
    // .flex(row, flex-start, flex-end);
    // flex-wrap: wrap-reverse;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(5, 20vw);
    margin-bottom: 77px;
    background-color: @content-background-color;
    box-shadow: 0 6px 40px rgba(46, 50, 45, 0.12);
    border-radius: 7px;
    width: 100%;
    padding: 8px;
}
}
</style>
