<template>
  <PieChartPainter
                  :componentWidth="this.maxChartWidth"
                  :diagramWidth="this.maxDiagramHeight"
                  :diagramHeight="this.maxDiagramHeight"
                  :viewBoxWidth="this.chartViewBoxHeight"
                  :viewBoxHeight="this.chartViewBoxHeight"
                  :legendHeight="this.maxDiagramHeight"
                  :legendWidth="this.legendWidth"

                  :selectedSeries="this.selectedSeries"

                  :angle="this.curAngle"

                  :pointName="this.chartSeries.unit"
                  :series="this.chartSeries.data"

                  :totalValue="this.measuredTotal.value"
                  :unitName="this.measuredTotal.unit"

                  :isEmpty="this.isEmpty"

                  :totalMode="this.totalMode"
                  :multData="this.currentMultiplier"

                  @chartPointerMove="(angle) => this.curAngle = (360 + this.curAngle + angle) % 360"
                  @elementSelected="this.elemSelected"
                  @changeTotalMode="this.totalMode = !this.totalMode"
  />
</template>


<script type="text/javascript">
import PieChartPainter from './ChartPainters/PieChartPainter.vue';
import varAnimator from '@/assets/varAnimator.js';

const pozNumOrZero = (val) => typeof val == 'number' && val > 0 ? val : 0;

export default {
  components: {PieChartPainter},
  emits: ['seriesSelect'],
  computed: {
    normalisedTotalValue() {
      return Object.keys(this.rawValues).reduce((accum, item) => accum + pozNumOrZero(this.rawValues[item].view), 0);
    },
    isEmpty() {
      return this.chartSeries.data.length < 1;
    },
    legendWidth() {
      return this.maxChartWidth != null ? this.maxChartWidth - this.maxDiagramHeight : null;
    },
    chartSeries() {
      const seriesData = {};
      let unitName = null;
      let startAngle = 0;

      Object.keys(this.rawValues).sort((k1, k2) => {
        const v1 = (typeof this.rawValues[k1].view == 'number' ? this.rawValues[k1].view : 0);
        const v2 = (typeof this.rawValues[k2].view == 'number' ? this.rawValues[k2].view : 0);

        return -(v1 - v2);
      }).forEach((key) => {
        const seriesId = '_' + key.split('_')[1];

        if (seriesData[seriesId] != null) return;
        if (unitName == null) unitName = this.rawPoints['_' + key.split('_')[2]].view;
        const curVal = pozNumOrZero(this.rawValues[key].view);
        const curAngle = this.valueToAngle(curVal);

        seriesData[seriesId] = {
          name: this.rawSeries[seriesId].view,
          value: (typeof this.rawValues[key].view == 'number' ? this.rawValues[key].view : 0),
          angle: curAngle,
          startAngle: startAngle,
        };
        seriesData[seriesId].percent = seriesData[seriesId].value / this.normalisedTotalValue;
        startAngle += curAngle;
      });

      return {unit: unitName, total: this.normalisedTotalValue, data: Object.values(seriesData)};
    },
    currentMultiplier() {
      const elem = this.multiplierSymbols.filter((item) => this.chartSeries.total >= item.multiplier).slice(-1)[0];
      return elem == null ? {symbol: '', multiplier: 1} : elem;
    },
    measuredTotal() {
      const elem = this.currentMultiplier;
      const value = (this.chartSeries.total / elem.multiplier).toFixed(2);
      return {value: value, unit: elem.symbol};
    },
  },
  props: {
    maxChartWidth: {default: null, required: false},
    maxDiagramHeight: {default: 140, required: false},
    rawSeries: {default: {
      '_0': {view: 'Первая серия'},
      '_1': {view: 'Вторая серия'},
    }},
    rawPoints: {default: {
      '_0': {view: 'Руб.'},
    }},
    rawValues: {default: {
      '_0_0': {'view': 1000},
      '_1_0': {'view': 500},
    }},
    multiplierSymbols: {default: [
      {symbol: '', multiplier: 1},
      {symbol: 'тыс', multiplier: Math.pow(10, 3)},
      {symbol: 'млн', multiplier: Math.pow(10, 6)},
      {symbol: 'млрд', multiplier: Math.pow(10, 9)},
      {symbol: 'трлн', multiplier: Math.pow(10, 12)},
      {symbol: 'квадр', multiplier: Math.pow(10, 15)},
    ]},
  },
  data: () => ({
    chartViewBoxHeight: 100,
    curAngle: 0,
    selectedSeries: null,
    totalMode: true,
  }),
  methods: {
    elemSelected(elemId, isLegend) {
      if (isLegend) {
        this.totalMode = false;
      } else {
        if (this.selectedSeries == elemId) {
          this.totalMode = !this.totalMode;
        } else {
          this.totalMode = false;
        }
      }
      this.rotateToElem(elemId);
      // this.$emit('seriesSelect', elemId);
    },
    rotateToElem(elemId) {
      if (this.isEmpty) return;
      const curElem = this.chartSeries.data[elemId];

      const angleStart = 360 - this.curAngle;
      const angleFinal = curElem.startAngle + curElem.angle / 2;

      if (angleStart === angleFinal) return;

      let direction = Math.sign(angleFinal - angleStart);
      let angleSize = Math.abs(angleFinal - angleStart);
      if (angleSize > 180) {
        angleSize = 360 - Math.abs(angleFinal - angleStart);
        direction = -direction;
      }

      varAnimator(angleStart, angleFinal, (progress) => {
        const currentAngle = angleStart + (direction * angleSize * progress);
        this.curAngle = 360 - (currentAngle + 360) % 360;
      }, 400, true);

      this.selectedSeries = elemId;
    },
    valueToAngle(value) {
      return value * 360 / this.normalisedTotalValue;
    },
  },
  mounted() {
    this.rotateToElem(0);
  },
};
</script>
