<template>
  <ObjSelectZeroField :baseField="this.baseField" :withoutTitle="true"/>
</template>

<script>
import ObjSelectZeroField from '../ObjSelectZeroField.vue';

export default {
  components: {
    ObjSelectZeroField,
  },
  props: [
    'baseField',
    'withoutTitle',
  ],
};
</script>
