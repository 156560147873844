import BaseZeroField from './BaseZeroField.js';


const emailPattern = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$';


class TextZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);

    if (fieldData.subtype == 'email') {
      this.pattern = emailPattern;
      this.invalideText = fieldData.invalide_text == null ? 'Требуемый формат email: <ваша почта>@<домен>.<доменная зона>' : fieldData.invalide_text;
      this.isBig = false;
    } else {
      this.pattern = fieldData.pattern === undefined ? this.pattern : fieldData.pattern;
      this.invalideText = fieldData.invalide_text === undefined ? this.invalideText : fieldData.invalide_text;
      this.rows = fieldData.big_text_rows === undefined ? this.rows : fieldData.big_text_rows;
      this.isBig = this.rows != null;
    }

    this.minLen = fieldData.min_len === undefined && this.minLen != null ? this.minLen : (fieldData.min_len == null ? 0 : Math.max(fieldData.min_len, 0));
    this.maxLen = fieldData.max_len === undefined && this.maxLen != null ? this.maxLen : (fieldData.max_len == null ? Infinity : fieldData.max_len);
    if (this.minLen > this.maxLen) this.maxLen = this.minLen;
  }

  _defaultValueValidator(newVal) {
    const ans = super._defaultValueValidator(newVal);
    if (ans != null) return ans;

    if (String(newVal).length < this.minLen) return `Требуется не менее ${this.minLen} символов!`;

    if (String(newVal).length > this.maxLen) return `Требуется не более ${this.maxLen} символов!`;

    if (this.pattern != null) {
      const matcher = new RegExp(this.pattern).test(newVal);
      if (!matcher) return this.invalideText != null ? this.invalideText : 'Неверный формат!';
    }
  }

  normaliseValue(newVal) {
    if (newVal == null) return null;
    return String(newVal);
  }
}

export default TextZeroField;
