<template>
    <div class="report-painter">
      <ChangeObjectForm v-if="this.report_form != null"
                  :raw_fields="this.report_form.fields ? this.report_form.fields : []"
                  :raw_actions="this.getActions"
                  actionsAlign="center"
                  :verticalMode="false"
                  @completeFormRichAction="this.formFilled"
      />

      <template v-if="this.raw_report_data != null && this.raw_report_data.__cached__ != null">
        <LabelComponent
                        :label_text="'Отчет сформирован: ' + String(this.cacheTime)"
                        :is_no_accent="true"
                        />
      </template>

      <LabelsDrawForm v-if="this.raw_report_data_form != null"
                      :raw_fields="this.raw_report_data_form.fields ? this.raw_report_data_form.fields : []"/>

      <div class="report-painter-diagrams" ref="report_diagrams">
        <template v-if="this.raw_report_data != null && this.drawDiagrams != null">
          <StaticDiagramWraper v-for="diagram in this.drawDiagrams" :key="diagram">
            <template v-slot="{ diagramWidth }">
              <component
                      :is="this.getDiagramComponent(diagram.type)"
                      :maxChartWidth="diagramWidth"
                      :maxDiagramHeight="225"
                      :typeName="diagram.type"
                      :rawSeries="diagram.series"
                      :rawPoints="diagram.points"
                      :rawValues="diagram.values"
                      @seriesSelect="'this.openPage'"
                      />
            </template>
          </StaticDiagramWraper>
        </template>
      </div>

      <div class="report-painter-first-level">
        <ReportLevelView v-if="this.raw_report_data != null" :params="this.firstLevelParams"/>
      </div>
    </div>
</template>

<script>
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelsDrawForm from '@/ioLayout/LabelsDrawForm.vue';
import ReportLevelView from './ReportLevelView.vue';

import PieChart from '@/units/charts/PieChart.vue';
// import HistogramChart from '@/units/charts/HistogramChart.vue';
// import GraphChart from '@/units/charts/GraphChart.vue';
import HybridChart from '@/units/charts/HybridChart.vue';
import LabelComponent from '@/units/RichLabel.vue';
import StaticDiagramWraper from '@/reportsLayout/StaticDiagramWraper.vue';

const DiagramTypesList = {
  'Гистограмма горизонтальная': HybridChart,
  'Гистограмма': HybridChart,
  'Гистограмма объемная': HybridChart,
  'Гистограмма горизонтальная объемная': HybridChart,
  'Гистограмма с накоплением': HybridChart,
  'График': HybridChart,
  'График с областями': HybridChart,
  'Смешанный': HybridChart,
  // 'График с накоплением': GraphChart,
  // 'График по шагам': GraphChart,
  'Кольцевая': PieChart,
  'Кольцевая объемная': PieChart,
  'Круговая': PieChart,
  'Круговая объемная': PieChart,
};

function normaliseValue(value) {
  // if (typeof(value) == typeof(true)) {
  //   return value ? 'Да' : 'Нет';
  // }

  // try {
  //   if (value && 'view' in value) {
  //     return value.view;
  //   }
  // } catch {}

  // if (!value || value == '') {
  //   return '';
  // }

  return value;
}

function packValueToNormal(values, colorColumn) {
  if (!values) {
    return {};
  }

  const ans = {};
  Object.keys(values).forEach(function(key) {
    if (key == colorColumn) return;

    ans[key] = {
      value: normaliseValue(values[key]),
    };
  });

  return ans;
}

function repackTableToNormalVersion(allColumns, level, rows) {
  const zeroLevelTypeData = {
    type: 'table',
    columns: {},
  };

  if (allColumns.length <= level) {
    return {
      next_level: [],
      next_type_data: level == 0 ? zeroLevelTypeData : null,
    };
  }

  const zeroNextLevel = [];

  let colorColumn = null;

  allColumns[level].forEach(function(elem) {
    if (elem.name == 'line_color') colorColumn = elem.type;
    else {
      zeroLevelTypeData.columns[elem.type] = {
        label: elem.name,
        name: elem.type,
        is_empty: true,
      };
    }
  });

  rows.forEach(function(elem) {
    const res = repackTableToNormalVersion(allColumns, level+1, elem.child);

    if (colorColumn != null && elem.values[colorColumn] && elem.values[colorColumn] != '') {
      res.color = 'other-color-0';
      res.exact_color = elem.values[colorColumn];
    }

    Object.keys(elem.values).forEach((key) => {
      if (elem.values[key] != null && elem.values[key] != '' && zeroLevelTypeData.columns[key]) zeroLevelTypeData.columns[key].is_empty = false;
    });

    res.columns = packValueToNormal(elem.values, colorColumn);

    zeroNextLevel.push(res);
  });

  const oldColumns = zeroLevelTypeData.columns;
  zeroLevelTypeData.columns = {};
  Object.keys(oldColumns).forEach((key) => {
    if (!oldColumns[key].is_empty) {
      zeroLevelTypeData.columns[key] = oldColumns[key];
    }
  });

  const ans = {
    next_level: zeroNextLevel,
    next_type_data: zeroLevelTypeData,
  };

  return ans;
}

function getColumnParentsName(curColumn, columns, isFirstLevel=true) {
  if (curColumn.parent == null) return '';
  const result = columns[curColumn.parent].name + getColumnParentsName(columns[curColumn.parent], columns, false);
  return isFirstLevel ? `, ${result}` : `, ${result}`;
}

function repackAdvTableToNormalVersion(columns, rows, firstLevel = true) {
  const zeroLevelTypeData = {
    type: 'table',
    columns: {},
  };

  if (!rows || rows.length < 1) {
    return {
      next_level: [],
      next_type_data: firstLevel ? zeroLevelTypeData : null,
    };
  }

  const zeroNextLevel = [];

  let colorColumn = null;

  Object.keys(columns).forEach((colType) => {
    if (columns[colType].name == 'line_color') colorColumn = colType;
    else {
      zeroLevelTypeData.columns[colType] = {
        label: columns[colType].name + getColumnParentsName(columns[colType], columns),
        name: colType,
        is_empty: true,
      };
    }
  });

  rows.forEach(function(elem) {
    const res = repackAdvTableToNormalVersion(columns, elem.child, false);

    if (colorColumn != null && elem.values[colorColumn] && elem.values[colorColumn] != '') {
      res.color = 'other-color-0';
      res.exact_color = elem.values[colorColumn];
    }

    Object.keys(elem.values).forEach((key) => {
      if (elem.values[key] != null && elem.values[key] != '' && zeroLevelTypeData.columns[key]) {
        zeroLevelTypeData.columns[key].is_empty = false;
      }
    });

    res.columns = packValueToNormal(elem.values, colorColumn);

    zeroNextLevel.push(res);
  });

  const oldColumns = zeroLevelTypeData.columns;
  zeroLevelTypeData.columns = {};
  Object.keys(oldColumns).forEach((key) => {
    if (!oldColumns[key].is_empty) {
      zeroLevelTypeData.columns[key] = oldColumns[key];
    }
  });

  const ans = {
    next_level: zeroNextLevel,
    next_type_data: zeroLevelTypeData,
  };

  return ans;
}

export default {
  components: {
    ChangeObjectForm,
    ReportLevelView,
    LabelComponent,
    StaticDiagramWraper,
    LabelsDrawForm,
  },
  props: {
    inputsDisabled: {
      default: false,
      required: false,
    },
    report_form: {
      default: null,
      required: false,
    },
    raw_report_data_form: {
      default: null,
      required: false,
    },
    raw_report_data: {
      default: null,
      required: false,
    },
    ignore_first_diagram: {
      default: false,
      required: false,
    },
  },
  data: () => ({

  }),
  emits: ['reportFormFilled'],
  watch: {
    // raw_report_data() {
    //   this.winResize();
    // },
  },
  methods: {
    formFilled(postData) {
      this.$emit('reportFormFilled', postData);
    },
    getDiagramComponent(diagramType) {
      return DiagramTypesList[diagramType];
    },
    // winResize() {
    //   let currentColumnsCount = 2;

    //   currentColumnsCount = window.innerWidth > window.transitionThreshold3 ? 3 : currentColumnsCount;
    //   currentColumnsCount = window.innerWidth <= window.transitionThreshold2 ? 1 : currentColumnsCount;

    //   if (this.drawDiagrams != null) currentColumnsCount = Math.min(this.drawDiagrams.length, currentColumnsCount);

    //   if (this.$refs.report_diagrams == null) return;

    //   const navRect = this.$refs.report_diagrams.getBoundingClientRect();
    //   this.chartsWidth = Math.max(navRect.width / currentColumnsCount, 200);
    // },
  },
  mounted() {
  //   window.addEventListener('resize', this.winResize);
  //   setTimeout(this.winResize, 1);
  // },
  // unmounted() {
  //   window.addEventListener('resize', this.winResize);
  },
  computed: {
    cacheTime() {
      return new FixDate(this.raw_report_data.__cached__).toLocaleString();
    },
    drawDiagrams() {
      if (this.raw_report_data == null) return null;
      return this.raw_report_data.diagrams.filter(
          (item, idx) => ((idx != 0 || !this.ignore_first_diagram) && this.getDiagramComponent(item.type) != null),
      );
    },
    getActions() {
      return [
        {
          system_name: 'build',
          view: 'Сформировать',
          is_loading: this.inputsDisabled,
        },
      ];
    },
    firstLevelParams() {
      const dataVersion = this.raw_report_data.version ? parseFloat(this.raw_report_data.version) : 2.6;
      return {
        drawStruct: (
          dataVersion < 3 ?
          repackTableToNormalVersion(this.raw_report_data.columns, 0, this.raw_report_data.rows) :
          repackAdvTableToNormalVersion(this.raw_report_data.columns, this.raw_report_data.rows)
        ),
        selfComponent: ReportLevelView,
      };
    },
  },
};
</script>

<style lang="less">
.report-painter {
  // padding: 17px;

  &-diagrams {
    min-width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);

    @media screen and (max-width: @big-screen-width) {
      grid-template-columns: repeat(2, auto);
    }

    @media screen and (max-width: @desktop-width) {
      grid-template-columns: auto;
    }

    padding-top: 24px;
    & > * {
      margin-bottom: 24px;
    }
  }

  &-first-level {
    margin-top: 27px;
    max-width: 100%;
    overflow: auto;
  }
}
</style>
