<template>
  <div class="navigation-sliders" ref="navigation_parent">

    <div class="slider-tiles-wrapper" v-if="this.get_inner_structure.length !== 0">
      <PageGroupReportDiagramTile v-for="item, i in this.get_list_reports_preview" :key="i"
                :draw_page="item"
                @isWide="(isWide) => item.isWide = isWide"
                class="large-tile content-background"
                :class="item.isWide ? 'wide-tile' : null"
                />

      <PageGroupReportTile v-for="(item, i) in this.get_list_reports" :key="i"
                :loc_data="item"
                class="small-tile content-background wide-tile"/>
    </div>

    <div
          class="content-box"
          v-if="this.get_list_reports_preview.length === 0 && this.get_list_reports.length === 0">
      <LabelComponent
        label_text="Нет доступных категорий"
        label_type="body/large/regular 16"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import PageGroupReportTile from './PageGroupReportTile.vue';
import PageGroupReportDiagramTile from './PageGroupReportDiagramTile.vue';
import LabelComponent from '@/units/RichLabel.vue';

export default {
  components: {
    LabelComponent,
    PageGroupReportTile,
    PageGroupReportDiagramTile,
  },
  data: () => ({

  }),
  witoutBackground: true,
  computed: {
    get_inner_structure() {
      const currentPage = this.$store.state.current_page_info;
      return currentPage ? currentPage.children : this.defautPageList;
    },
    get_list_reports() {
      return this.get_inner_structure.filter(function(item) {
        return !item.enable_diagram_preview;
      });
    },
    get_list_reports_preview() {
      return this.get_inner_structure.filter((item) => item.enable_diagram_preview);
    },
  },
  methods: {

  },
  mounted() {
    const element = document.getElementById('content-slider');
    if (element) {
      element.classList.remove('content-wrapper-type-1__slider__content__back');
      element.classList.remove('content-wrapper-type-2__page__content__slider__back');
    }
  },
  unmounted() {

  },
  props: {
    defautPageList: {
      default: [],
      required: false,
    },
  },
};
</script>

<style  lang="less">

.slider-tiles-wrapper {
  display: grid;
  column-gap: @base-gap;
  row-gap: @base-gap;
  grid-template-columns: repeat(10, 1fr);

  @media (max-width: @two-screen-width) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: @extrim-screen-width) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: @very-large-screen-width) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: @large-screen-width) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: @big-screen-width) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: @desktop-width) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: @tablet-width) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: @double-micro-gap;
    row-gap: @double-micro-gap;
    .wide-tile {
      grid-column: span 2;
    }
  }

  @media (max-width: @mobile-width) {
    grid-template-columns: 1fr;
    .wide-tile {
      grid-column: 1;
    }
  }
}

.large-tile {
  grid-row: span 3;
}

.small-tile {
  align-content: center;
}
</style>
