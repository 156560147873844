<template>
  <div class="page-editor-wraper flex-column">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>

    <ZeroForm :formData="this.drawData" @callCommand="this.savePage"/>

    <ButtonComponent
                      v-if="this.isAccesable"
                      label="Редактировать доступы"
                      elementType="default"
                      @click="this.openAccessEditor"
                      />
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

import reportIconList from '@/assets/reportIconList.js';
import pageAddOrChange from '@/assets/dataSources/navigation/pageAddOrChange.js';
import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure';

import PageAccessEditor from '@/subscriptionLayout/PageAccessEdtor.vue';

import ZeroForm from '@/zeroForms/draw/ZeroForm.vue';
import PagesContainer from '@/zeroForms/behaviour/objContainers/additional/PagesContainer';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
    ZeroForm,
  },
  data: () => ({
    savingError: null,
    isSaving: false,

    useBasedParent: false,
    curBasedPageValue: null,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  mounted() {
    this.useBasedParent = this.initial_use_template_parent;
  },
  methods: {
    savePage(form, target) {
      if (!form.isValid) {
        console.log('NOT VALID', form.notValidList);
        this.savingError = 'Исправьте все ошибки!';
      }
      if (this.isSaving || !form.isValid) return;

      const saveForm = new FormData();
      form.packToFormData(saveForm);

      // const newTemplatePageUid = saveForm.get('template_page_uid');

      // console.log('newTemplatePageUid', newTemplatePageUid);
      // return;

      // if (newTemplatePageUid != this.initial_template_page) {
      //   saveForm.append();
      // }

      // if ()

      this.savingError = null;
      this.isSaving = true;
      // console.log('saveForm', saveForm);
      // saveForm.entries().forEach((pair) => console.log(pair[0]+ ', ' + pair[1]));
      pageAddOrChange(this.page_uid == null, saveForm, (result) => {
        this.isSaving = false;

        if (result._error == true) {
          if (result.status == null) this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.savingError = `${result.text} Статус: ${result.status}`;
          return;
        }

        getWebSiteStructure(async (result) => {
          this.$store.commit('web_site_struct_mut', result);

          const parentPage = this.$store.getters.pageByUid(saveForm.get('parent_page_uid'));

          await window.openAcceptWindow('Панель навигации обновлена!');

          this.$router.replace(parentPage == null ? '/' : parentPage.fullUrl);

          this.$emit('result');
        });
      });
    },
    openAccessEditor() {
      window.openWindow({
        caption: 'Редактирование доступов',
        component: PageAccessEditor,
        componentProps: {
          page_uid: this.page_uid,
        },
      });
    },
    basePageChange(data) {
      if (this.curBasedPageValue == data.value) return;
      this.curBasedPageValue = data.value;
    },
    parentUsingChange(data) {
      if (this.useBasedParent == data.value) return;
      this.useBasedParent = data.value;
    },
  },
  computed: {
    isAccesable() {
      return this.page_uid != null && (this.page_type != 'group');
    },
    page_uid() {
      return this.params.page_uid;
    },
    page_type() {
      return this.params.page_type;
    },
    initial_name() {
      return this.params.initial_name == null ? '' : this.params.initial_name;
    },
    initial_icon() {
      return this.params.initial_icon;
    },
    initial_parent() {
      return this.params.initial_parent;
    },
    initial_template_page() {
      return this.params.initial_template_page;
    },
    initial_use_template_parent() {
      return this.params.initial_use_template_parent;
    },
    data_source_uid() {
      return this.params.data_source_uid;
    },
    initial_object_uid() {
      return this.params.initial_object_uid;
    },
    initial_is_object_list() {
      return this.params.initial_is_object_list;
    },
    initial_open_new_tab() {
      return this.params.initial_open_new_tab;
    },
    initial_enable_diagram_preview() {
      return this.params.initial_enable_diagram_preview !== false;
    },
    initial_can_edit_storage() {
      return this.params.initial_can_edit_storage !== false;
    },
    initial_in_fast_panel() {
      return this.params.initial_in_fast_panel !== false;
    },
    initial_url() {
      return this.params.initial_url;
    },

    getNotNullIcon() {
      if (this.initial_icon != null) return {uid: this.initial_icon};

      return {uid: '/icons/reports/' + reportIconList[Math.floor(Math.random()*reportIconList.length)]};
    },
    drawData() {
      return {
        fields: [
          {
            type: 'text',
            uid: 'page_uid',
            view: 'page_uid',
            required: false,
            is_hidden: true,
            default: this.page_uid,
          },
          {
            type: 'text',
            uid: 'page_type',
            view: 'page_type',
            required: true,
            is_hidden: this.page_type != null,
            default: this.page_type,
          },
          {
            type: 'text',
            view: 'Наименование',
            uid: 'name',
            required: true,
            is_hidden: false,
            default: this.initial_name,
          },
          {
            type: 'boolean',
            view: 'Добавить в панель быстрого доступа',
            uid: 'in_fast_panel',
            nullable: true,
            required: false,
            is_hidden: false,
            default: this.initial_in_fast_panel,
          },
          {
            type: 'boolean',
            view: 'Открывать в новой вкладке',
            uid: 'open_new_tab',
            nullable: true,
            required: false,
            is_hidden: false,
            default: this.initial_open_new_tab,
          },
          {
            type: 'boolean',
            view: 'Выводить диаграмму на предпросмотр (при наличии)',
            uid: 'enable_diagram_preview',
            nullable: true,
            required: false,
            is_hidden: this.page_type != 'report',
            default: this.initial_enable_diagram_preview,
          },
          {
            type: 'boolean',
            view: 'Разрешено редактировать структуру',
            uid: 'can_edit_storage',
            nullable: true,
            required: false,
            is_hidden: this.page_type != 'storage',
            default: this.initial_can_edit_storage,
          },
          {
            type: 'entity_selector',
            obj_type: '_internal.icon',
            view: 'Иконка страницы',
            uid: 'icon_path',
            nullable: true,
            required: false,
            is_hidden: false,
            obj_container: null,
            default: this.getNotNullIcon,
          },
          {
            type: 'entity_selector',
            obj_type: '_internal.page',
            view: 'Базовая страница',
            uid: 'template_page_uid',
            default: this.initial_template_page,
            error: null,
            nullable: true,
            required: false,
            disabled: false,
            is_hidden: false,
            obj_container: new PagesContainer('page_template', [this.page_uid]),
            change_handler: this.basePageChange,
          },
          {
            type: 'boolean',
            view: 'Использовать папку базовой страницы',
            uid: 'use_template_parent',
            nullable: true,
            required: false,
            is_hidden: this.curBasedPageValue == null,
            default: this.initial_use_template_parent,
            change_handler: this.parentUsingChange,
          },
          {
            type: 'entity_selector',
            obj_type: '_internal.page',
            view: 'Папка',
            uid: 'parent_page_uid',
            default: this.initial_parent,
            error: null,
            nullable: true,
            required: false,
            disabled: false,
            is_hidden: this.curBasedPageValue != null && this.useBasedParent,
            obj_container: new PagesContainer('page_group', [this.page_uid]),
          },
          {
            type: 'text',
            uid: 'source_uid', // Для Файлового Хранилища
            view: 'DataSource',
            required: this.page_type == 'storage',
            is_hidden: true,
            default: this.data_source_uid,
          },
          {
            type: 'text',
            uid: 'report_source_uid', // Для Отчетов
            view: 'DataSourceReport',
            nullable: true,
            required: false,
            is_hidden: true,
            default: this.data_source_uid,
          },
          {
            type: 'text',
            uid: 'report_uid', // Для Отчетов
            view: 'ReportUid',
            nullable: true,
            required: false,
            is_hidden: true,
            default: this.initial_object_uid,
          },
          {
            type: 'text',
            uid: 'url', // Для Отчетов
            view: 'Ссылка',
            nullable: this.page_type != 'ext_link',
            required: this.page_type == 'ext_link',
            is_hidden: this.page_type != 'ext_link',
            default: this.initial_url,
          },
        ],
        actions: [
          {
            view: 'Сохранить',
            type: 'submit',
            command: 'save',
            uid: 'save',
            is_loading: this.isSaving,
          },
        ],
      };
    },
  },
};
</script>
