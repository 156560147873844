<!-- eslint-disable no-irregular-whitespace -->
<template>
    <InputFieldTemplate @focusChange="'this.setFocus'"
                        v-show="!this.baseField.isHidden"
                        @paste="this.testPaste"
                        :disabled="this.baseField.disabled"
                        :hideData="this.baseField.value == null"
                        :is_required="this.baseField.required"
                        :title="this.baseField.view"
                        :flexHeight="true"
                        :error="this.baseField.errorText">
      <div class="ex-table" :style="{'grid-template-columns': `repeat(${this.drawData.columns.length}, 1fr) 20px`}">
        <div class="ex-table-cell-header" v-for="column in this.drawData.columns" :key="column">
          <h2 class="input_box_title">
            <span>{{ column.view }} </span>
            <span class="error-text" v-if="column.required">* </span>
          </h2>
        </div>
        <div class="ex-table-cell-header">
          <h2 class="input_box_title">
            <span>X</span>
          </h2>
        </div>
        <template v-for="row, rIdx in this.drawData.rows" :key="row">
          <div class="ex-table-cell-value" :class="{
            'ex-table-cell-value-fr': rIdx == 0,
            'ex-table-cell-value-fc': cIdx == 0,
            }"
            v-for="cell, cIdx in row.fields"
            :key="cell"
            @click="this.changeSelect(rIdx, cIdx)"
            >
            <span class="error-text" v-if="cell.params.baseField.errorText != null">{{ cell.params.baseField.errorText }}<br/></span>
            <!-- {{ cell.params.baseField.value }} -->
            <component :ref="`val_cell_${rIdx}_${cIdx}`"
               :is="cell.component"
               v-bind="cell.params"
            />
            <div class="ex-table-selector" v-if="this.selectedCell.r == rIdx && this.selectedCell.c == cIdx"></div>
          </div>
          <div @click="this.removeRow(rIdx)"> O </div>
        </template>
      </div>
      <span @click="this.addRow">Добавить запись</span>
    </InputFieldTemplate>
</template>

<script>
import InputFieldTemplate from './InputFieldTemplate.vue';

export default {
  components: {
    InputFieldTemplate,
  },
  data: () => ({
    selectedCell: {
      r: 0,
      c: 0,
    },
  }),
  props: [
    'baseField',
    // 'validator',
    // 'changeHandler',
  ],
  computed: {
    drawData() {
      return {
        columns: Object.values(this.baseField.templateForm.fields),
        rows: (this.baseField.value == null ? [] : this.baseField.value).map((form) => form.renderData),
      };
    },
  },
  mounted() {

  },
  methods: {
    changeSelect(r, c) {
      this.selectedCell.r = r;
      this.selectedCell.c = c;

      const refCell = this.$refs[`val_cell_${this.selectedCell.r}_${this.selectedCell.c}`];
      if (refCell == null || refCell[0] == null || refCell[0].setFocus == null) return;
      refCell[0].setFocus(true);
    },
    addRow() {
      this.baseField.addRow();
    },
    removeRow(rowId) {
      this.baseField.removeRow(rowId);
    },
    testPaste(event) {
      event.preventDefault();
      const plainData = event.clipboardData.getData('text/plain');
      const rawHtmlData = event.clipboardData.getData('text/html');

      let parsedTable = document.createElement( 'html' );
      parsedTable.innerHTML = rawHtmlData;
      parsedTable = parsedTable.getElementsByTagName('table')[0];

      if (parsedTable != null) {
        parsedTable = parsedTable.getElementsByTagName('tbody')[0];
        if (parsedTable != null) {
          parsedTable = Array.from(parsedTable.getElementsByTagName('tr')).map((row) => {
            return Array.from(row.getElementsByTagName('td')).map((cell) => {
              cell.innerHTML = cell.innerHTML.trim().replaceAll(/\s\s*/gi, ' ');
              return Array.from(cell.childNodes).map((subElem) => {
                if (subElem.tagName == 'BR') return '\n';
                return subElem.textContent.trim();
              }).join('');
            });
          });
        }
      }

      if (parsedTable != null) {
        let rowCounter = 0;
        for (const parsedRow of parsedTable) {
          let colCounter = 0;
          let curRow = this.drawData.rows[this.selectedCell.r + rowCounter];
          while (curRow == null) {
            this.addRow();
            curRow = this.drawData.rows[this.selectedCell.r + rowCounter];
          }
          for (const parsedColumn of parsedRow) {
            const curCell = curRow.fields[this.selectedCell.c + colCounter];
            if (curCell != null) {
              curCell.params.baseField.setValue(parsedColumn);
            }
            colCounter += 1;
          }
          rowCounter += 1;
        }
      } else if (String(plainData).length > 0) {
        let curRow = this.drawData.rows[this.selectedCell.r];
        while (curRow == null) {
          this.addRow();
          curRow = this.drawData.rows[this.selectedCell.r];
        }

        const curCell = curRow.fields[this.selectedCell.c];

        if (curCell != null) curCell.params.baseField.setValue(plainData);
      }
    },
  },
};
</script>

<style lang="less">
@import '/src/assets/less/constants.less';

.ex-table {
  display: grid;

  &-cell {
    &-header {
      text-align: center;
    }

    &-value {
      position: relative;
      cursor: cell;

      border: 1px solid @no-accent-text-color;
      border-left-width: 0;
      border-top-width: 0;

      padding: @small-gap;

      &-fr {
        border-top-width: 1px;
      }

      &-fc {
        border-left-width: 1px;
      }
    }
  }
}

.ex-table-selector {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 2px solid @accent-text-color;
}

</style>
