<template>
  <span @click="this.reselectObject()"
          >{{
    this.baseField.value == null ? '-' : this.baseField.value.view
  }}</span>
</template>

<script>
import DefaultObjSelector from './DefaultObjSelector.vue';

export default {
  components: {

  },
  data: () => ({

  }),
  props: [
    'baseField',
  ],
  computed: {
    objSelector() {
      return this.baseField.renderObjSelector;
    },
  },
  mounted() {

  },
  methods: {
    async reselectObject() {
      const result = await window.openWindow({
        caption: 'Выбор объекта',
        component: DefaultObjSelector,
        componentProps: {
          baseField: this.baseField,
        },
      });

      if (result !== undefined) {
        this.baseField.setValue(result);
      }
    },
  },
};
</script>
