<template>
  <div class = "profile-frame" ref = "profile_frame">
    <div class = "profile-frame__preview">
      <IconComponent :url="'/icons/system/human.svg'" v-if="this.smallProfile" color="black" size="28" class="icon-over-transparency-color"/>
      <LabelComponent v-if = "!this.smallProfile" class = "profile-frame__username"
                      label_type = 'button/large/medium 14'
                      :label_text = "get_user_full_name"/>

      <IconComponent v-if = "!this.smallProfile"
                     class="icon-over-transparency-color"
                     url = "/icons/system/caret-circle-down.svg" size = "16"
                     :class = "{'profile-frame__icon':true, 'profile-frame__icon-opened':this.dropped}"/>
    </div>
    <div class = "profile-frame__user-menu" v-if = "this.dropped">
      <span ref = "dropdown">
        <ProfileUserMenuMobile v-if="this.smallProfile" :menuItems = "menuItems"
                               @close_modal = "dropped=false" @select_elem = "select_elem"/>
        <ProfileUserMenu v-else :menuItems = "menuItems" @select_elem = "select_elem"/>
      </span>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';
import ProfileUserMenu from './ProfileUserMenu.vue';
import ProfileUserMenuMobile from './ProfileUserMenuMobile.vue';
import logoutUser from '@/assets/dataSources/auth/logoutUser.js';
import isMobileMixin from '@/assets/mixins/isMobileMixin.js';


export default {
  name: 'ProfileFrame',

  data: () => ({
    dropped: false,
  }),
  mixins: [isMobileMixin],
  components: {
    IconComponent,
    LabelComponent,
    ProfileUserMenu,
    ProfileUserMenuMobile,
  },
  computed: {
    smallProfile() {
      return this.isMobWidth || this.isLandscape;
    },
    get_user_info() {
      return this.$store.state.user_obj;
    },
    get_user_full_name() {
      if (!this.get_user_info) return 'Anon';
      if (!this.get_user_info['first_name'] && !this.get_user_info['last_name']) return 'Неизвестное имя';
      return [this.get_user_info['first_name'], this.get_user_info['last_name']].join(' ');
    },
    menuItems() {
      let ans = [];
      if (!this.get_user_info || this.get_user_info.is_anonymous) {
        ans = [
          !this.$store.getters.iosDemoMode ? {
            name: 'Сайт продукта',
            icon: '/icons/system/globe.svg',
            separatorBottom: true,
            callFunc: () => {
              location.href = 'https://zerofactor.ru/';
            },
          } : null,
          !this.$store.getters.iosDemoMode ? {
            name: 'Документация',
            icon: '/icons/system/help_round.svg',
            separatorBottom: true,
            callFunc: () => {
              location.href = 'https://docs.zerofactor.ru/';
            },
          } : null,
          {
            name: 'Вход',
            icon: '/icons/system/sign-out.svg',
            callFunc: () => {
              location.href = '/login';
            },
          },
        ];
      } else {
        ans = [
          {
            name: 'Профиль',
            separatorBottom: true,
            icon: '/icons/system/badge-002.svg',
            callFunc: () => {
              this.$router.push('/settings/profile/');
            },
          },
          (!this.$store.getters.iosDemoMode && this.$store.getters.isUserOwner !== false) ? {
            name: 'Управление подпиской',
            separatorBottom: true,
            icon: '/icons/system/finres_icon.svg',
            callFunc: () => {
              this.$router.push('/settings/subscription/');
            },
          } : null,
          !this.$store.getters.iosDemoMode ? {
            name: 'Сайт продукта',
            icon: '/icons/system/globe.svg',
            separatorBottom: true,
            callFunc: () => {
              location.href = 'https://zerofactor.ru/';
            },
          } : null,
          !this.$store.getters.iosDemoMode ? {
            name: 'Документация',
            icon: '/icons/system/help_round.svg',
            separatorBottom: true,
            callFunc: () => {
              location.href = 'https://docs.zerofactor.ru/';
            },
          } : null,
          {
            name: 'Выход',
            icon: '/icons/system/sign-out.svg',
            callFunc: () => {
              logoutUser(() => {
                window.location.href = '/login/?next=' + this.$route.fullPath;
              });
            },
          },
        ];
      }

      return ans.filter((elem) => elem != null);
    },
  },

  props: ['user_info'],
  created() {
    this.menuItems.map((menuItem, idx) => {
      menuItem.id = idx;
    });
    document.addEventListener(
        'click',
        this.clickOutHandler,
    );
  },
  methods: {
    select_elem(id) {
      this.dropped = false;
      this.menuItems[id].callFunc();
    },
    to_exit() {
      document.cookie = 'sessionKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      document.cookie = 'sessionKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=' + window.location.pathname;
      location.reload();
    },
    clickOutHandler(event) {
      let container = this.$refs.profile_frame;
      if (container && !container.contains(event.target)) {
        this.dropped = false;
      } else {
        container = this.$refs.dropdown;
        if (!container || !container.contains(event.target)) {
          this.dropped = !this.dropped;
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener(
        'click',
        this.clickOutHandler,
    );
  },
};

</script>

<style lang="less">
.profile-frame {
  .flex(column, flex-start, flex-start);

  &__preview {
    .flex(row, space-between, center);
    position: relative;
    user-select: none;

    &:hover {
      cursor: pointer
    }
  }

  &__username {
    margin-left: 5px;
    color: @over-transparency-text-color;
  }

  &__user-menu {
    width: 100%;
    overflow: visible;
    position: relative;
  }

  &__icon {
    margin-left: 10px;
    transition: transform 0.2s;

    &-opened {
      transform: rotate(-180deg);
    }
  }
}
</style>
