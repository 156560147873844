class BaseZeroField {
  constructor(fieldIdx, fieldData, parent, projectData) {
    this.parent = parent;
    const curProjData = projectData == null ? (parent == null ? {} : parent.projectData) : projectData;
    this.projectData = curProjData;
    this.fieldIdx = fieldIdx;

    this.changeListeners = [];
    this.formFieldChangeListeners = [];
    this.commandListeners = [];

    this.unpackFieldData(fieldData);

    this.setDefault(fieldData.default);
    this.setValue(fieldData.value == null ? fieldData.default : fieldData.value);
  }

  reinitRawData(fieldData) {
    this.unpackFieldData(fieldData);
    this.setDefault(fieldData.default);

    if (fieldData.value !== undefined) this.setValue(fieldData.value);
  }

  unpackFieldData(fieldData) {
    this.uid = this.uid === undefined ? fieldData.uid : this.uid;
    this.iid = fieldData.iid === undefined ? this.iid : fieldData.iid;
    this.type = this.type === undefined ? fieldData.type : this.type;

    this.command = fieldData.command === undefined ? this.command : fieldData.command;

    this.view = fieldData.view === undefined ? this.view : fieldData.view;
    this.errorText = fieldData.error === undefined ? this.error : fieldData.error;

    this.isHidden = fieldData.is_hidden === undefined ? this.isHidden : fieldData.is_hidden;
    this.disabled = fieldData.disabled === undefined ? this.disabled : fieldData.disabled;
    this.required = fieldData.required === undefined ? this.required : fieldData.required;
    this.nullable = fieldData.nullable === undefined ? this.nullable : fieldData.nullable;

    this.validator = fieldData.validator === undefined ? this.validator : fieldData.validator;
    this.changeHandler = fieldData.change_handler === undefined ? this.changeHandler : fieldData.change_handler;
  }

  _defaultValueValidator(newVal) {
    if (newVal == null && !this.nullable) return 'Значение должно быть определено!';
    if ((newVal === null || newVal === undefined || newVal === '') && this.required) return 'Это обязательное поле!';
    return null;
  }

  validateValue(newVal) {
    if (this.validator != null) return this.validator(this._defaultValueValidator, newVal);
    return this._defaultValueValidator(newVal);
  }

  get isValid() {
    return this.errorText == null;
  }

  normaliseValue(val) {
    if (val == null) return null;
    return val;
  }

  addChangeListener(listener) {
    this.changeListeners.push(listener);
  }

  removeChangeListener(listener) {
    const index = this.changeListeners.indexOf(listener);
    if (index > -1) this.changeListeners.splice(index, 1);
  }

  addFormFieldChangeListener(listener) {
    this.formFieldChangeListeners.push(listener);
  }

  removeFormFieldChangeListener(listener) {
    const index = this.formFieldChangeListeners.indexOf(listener);
    if (index > -1) this.formFieldChangeListeners.splice(index, 1);
  }

  formFieldUpdated(data) {
    this.formFieldChangeListeners.forEach((listener) => listener(data));
    if (this.parent != null && this.parent.formFieldUpdated != null) this.parent.formFieldUpdated({child: this, target: data.target, before: data});
  }

  addCommandListener(listener) {
    this.commandListeners.push(listener);
  }

  removeCommandListener(listener) {
    const index = this.commandListeners.indexOf(listener);
    if (index > -1) this.commandListeners.splice(index, 1);
  }

  callCommand(target) {
    if (target.command == null) return;
    this.commandListeners.forEach((listener) => listener(target));
    if (this.parent != null && this.parent.callCommand != null) this.parent.callCommand(target);
  }

  valueUpdated() {
    this.changeListeners.forEach((listener) => listener(this));
    if (this.parent != null && this.parent.formFieldUpdated != null) this.parent.formFieldUpdated({child: this, target: this});
    if (this.changeHandler != null) this.changeHandler(this);
    this.callCommand(this);
  }

  setDefault(newVal) {
    this.default = this.normaliseValue(newVal);
  }

  setValue(newVal) {
    this.value = this.normaliseValue(newVal);
    this.errorText = this.errorText == null || this.firstInit != undefined ? this.validateValue(this.value) : this.errorText;
    this.firstInit = true;
    this.valueUpdated();
  }

  // _validator(...args) {
  //     if (this.validator != null) return this.validator(...args);
  // }

  // _changeHandler(...args) {
  //     if (this.validchangeHandlerator != null) return this.changeHandler(...args);
  // }

  get projectInputComponents() {
    if (this.projectData == null) return {};
    return this.projectData.inputComponents == null ? {} : this.projectData.inputComponents;
  }

  get renderData() {
    const curComponent = this.projectInputComponents[this.type];

    if (curComponent == null) {
      console.warn('Draw component not found!', this.type, this);
      return null;
    }

    return {
      component: curComponent,
      params: this.drawParams,
    };
  }

  get drawParams() {
    return {
      baseField: this,
      // validator: this._validator,
      // changeHandler: this._changeHandler,
    };
  }

  packToFormData(container, prefix='') {
    if (!this.isValid) return;
    if (this.value == null) return;

    container.append(prefix + this.uid, String(this.formDataValue));
  }

  get formDataValue() {
    return String(this.value);
  }

  // unpackValue(rawVal) {
  //     if (rawVal == null) return null;

  //     return rawVal;
  // }

  // packValue(val) {
  //     if (val == null) return null;
  //     return String(val);
  // }
}


export default BaseZeroField;
