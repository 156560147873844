import BaseZeroField from './BaseZeroField.js';


class NumberZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);

    this.min = fieldData.min === undefined && this.min != null ? this.min : (fieldData.min == null ? -Infinity : fieldData.min);
    this.max = fieldData.max === undefined && this.max != null ? this.max : (fieldData.max == null ? Infinity : fieldData.max);
    if (this.min > this.max) this.max = this.min;
    this.isFloat = fieldData.is_float === undefined && this.isFloat != null ? this.isFloat : (fieldData.is_float == true);
  }

  get canMinus() {
    return this.min < 0;
  }

  get canDot() {
    return this.isFloat;
  }

  _defaultValueValidator(newVal) {
    const ans = super._defaultValueValidator(newVal);
    if (ans != null) return ans;

    if (String(newVal).includes('e')) return 'Число не должно содержать e!';
    if (!this.canMinus && String(newVal).includes('-')) return 'Число не может быть отрицательным!';
    if (!this.canDot && String(newVal).includes('.')) return 'Число не может быть дробным!';

    newVal = parseFloat(newVal);

    if (isNaN(newVal)) return 'Неверный формат';

    if (newVal < this.min || newVal > this.max) return `Число должно быть в диапазоне от ${this.min} до ${this.max}`;
  }

  normaliseValue(newVal) {
    // eslint-disable-next-line no-irregular-whitespace
    newVal = String(newVal).replace(/[  \n\r]/, '').replace(',', '.');
    return parseFloat(newVal);
  }

  // setValue(newVal) {
  //     if (newVal < this.min) newVal = this.min;
  //     if (newVal > this.max) newVal = this.max;
  //     if (!this.isFloat) newVal = parseInt(newVal);
  //     super.setValue(newVal);
  // }
}

export default NumberZeroField;
