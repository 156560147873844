class BaseObjZeroContainer {
  constructor() {
    this.drawObjects = [];
    this.searchForm = null;
  }

  researchData(searchForm) {
    this.drawObjects = [];
    this.searchForm = searchForm;
  }

  normaliseUploadPageRange(pageNum, pageSize) {
    let startIdx = pageNum * pageSize;
    let endIdx = startIdx + pageSize - 1;

    let alreadyExistsRange = false;

    if (endIdx < startIdx) return;

    if (startIdx < this.drawObjects.length) alreadyExistsRange = true;
    if (endIdx < this.drawObjects.length) alreadyExistsRange = true;

    let minNullElem = null;
    let maxNullElem = null;

    if (alreadyExistsRange) {
      for (let i=startIdx; i <= endIdx; i++) {
        if (this.drawObjects[i] == null) {
          if (minNullElem == null) minNullElem = i;
          maxNullElem = i;
        }
      }
    } else {
      minNullElem = startIdx;
      maxNullElem = endIdx;
    }

    if (minNullElem == null) return;
    startIdx = minNullElem;
    endIdx = maxNullElem;

    return {
      startIdx,
      endIdx,
    };
  }

  async getObjectsRange(startIdx, endIdx) {
    return 'Not implemented';
  }

  async uploadPage(pageNum, pageSize) {
    const normValue = this.normaliseUploadPageRange(pageNum, pageSize);

    if (normValue == null) return;

    const startIdx = normValue.startIdx;
    const endIdx = normValue.endIdx;

    const newObjects = await this.getObjectsRange(startIdx, endIdx);

    if (!Array.isArray(newObjects)) return newObjects;

    newObjects.forEach((newObj, idx) => this.drawObjects[startIdx + idx] = newObj);

    return {d: 'ok'};
  }

  get searchView() {
    if (this.searchForm == null) return;
    const reqField = this.searchForm.getfieldByUid('search_views');
    if (reqField == null) return;

    return reqField.value == '' || reqField.value == null ? null : String(reqField.value);
  }

  getSortedData() {
    return this.drawObjects.filter(() => true).sort((a, b) => {
      const aView = a.view == null ? '' : String(a.view).toUpperCase();
      const bView = b.view == null ? '' : String(b.view).toUpperCase();

      let searchView = this.searchView;

      if (searchView != null) {
        searchView = searchView.toUpperCase();
        const isAstarts = aView.startsWith(searchView);
        const isBstarts = bView.startsWith(searchView);

        if (isAstarts && !isBstarts) return -1;
        if (!isAstarts && isBstarts) return 1;
      }

      if (aView < bView) return -1;
      if (aView > bView) return 1;
      return 0;
    });
  }
}

export default BaseObjZeroContainer;
