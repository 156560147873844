<template>
  <ButtonComponent
    elementType="default"
    :iconUrl="'/icons/system/magnifying-glass.svg'"
    @click_to_button="this.reselectObject"
  />

  <LabelComponent v-if="this.baseField.value != null" :label_text="this.baseField.value.view"/>
  <LabelComponent v-else label_text="-"/>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import InternalPageSelector from './InternalPageSelector.vue';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
  },
  data: () => ({

  }),
  props: [
    'baseField',
  ],
  computed: {
    objSelector() {
      return this.baseField.renderObjSelector;
    },
  },
  mounted() {

  },
  methods: {
    async reselectObject() {
      const result = await window.openWindow({
        caption: 'Выбор объекта',
        component: InternalPageSelector,
        componentProps: {
          baseField: this.baseField,
        },
      });

      if (result !== undefined) {
        this.baseField.setValue(result);
      }
    },
  },
};
</script>
