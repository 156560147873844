<template>
  <div class="static-diagram-frame" ref="wraper_root">
    <div class="static-diagram-wraper">
      <slot :diagramWidth="this.wraperWidth" v-if="this.wraperWidth > 0"></slot>
    </div>
  </div>
</template>

<script type="text/javascript">

export default {
  components: {

  },
  data: ()=> ({
    wraperWidth: -1,
  }),
  mounted() {
    window.addEventListener('resize', this.recalculateWraperWidth);
    setTimeout(this.recalculateWraperWidth, 1);
  },
  unmounted() {
    window.removeEventListener('resize', this.recalculateWraperWidth);
  },
  methods: {
    recalculateWraperWidth(last=false) {
      if (!this.$refs.wraper_root) return;
      this.wraperWidth = this.$refs.wraper_root.getBoundingClientRect().width;
      if (!last) {
        setTimeout(() => this.recalculateWraperWidth(true), 1);
      }
    },
  },
};
</script>

<style lang="less">

.static-diagram-wraper {
  max-width: 1px;
  .flex(row, start, start);
  overflow-x: visible;
}

</style>
