class ZeroFormCommand {
  constructor(commandIdx, commandData, basedForm, projectData) {
    const curProjData = projectData == null ? (parent == null ? {} : parent.projectData) : projectData;
    this.projectData = curProjData;

    this.commandIdx = commandIdx;
    this.basedForm = basedForm;

    this.reinitRawData(commandData);
  }

  reinitRawData(rawData) {
    this.uid = rawData.uid === undefined ? this.uid : rawData.uid;
    this.requreAccept = rawData.requreAccept === undefined ? this.requreAccept : rawData.requreAccept == true;
    this.handler = rawData.handler === undefined ? this.handler : rawData.handler;
  }
}


function zeroFormCommandFactory(commandIdx, commandData, basedForm) {
  return new ZeroFormCommand(commandIdx, commandData, basedForm);
}


export default zeroFormCommandFactory;
