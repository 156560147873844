import zeroFormCommandFactory from './ZeroFormCommand.js';
import zeroFieldFactory from './ZeroField.js';
import zeroActionFactory from './ZeroAction.js';

class ZeroForm {
  constructor(formIdx, formData, parent, projectData) {
    this.parent = parent;
    const curProjData = projectData == null ? (parent == null ? {} : parent.projectData) : projectData;
    this.projectData = curProjData;

    this.formIdx = formIdx;

    this.commandListeners = [];
    this.formFieldChangeListeners = [];

    this.commands = {};
    this.fields = {};
    this.actions = {};

    this.reinitRawData(formData);
  }

  reinitRawData(formData) {
    this.uid = this.uid === null ? formData.uid : this.uid;
    this.drawStyle = formData.draw_style == null ? 'column' : formData.draw_style;
    this.entityView = formData.entity_view === undefined ? this.entityView : formData.entity_view;
    this.formLabel = formData.form_label === undefined ? this.formLabel : formData.form_label;

    const rawCommands = formData.commands == null ? [] : formData.commands;
    const rawFields = formData.fields == null ? [] : formData.fields;
    const rawActions = formData.actions == null ? [] : formData.actions;

    Object.values(rawCommands).filter((elem) => elem.uid != null).forEach((command) => {
      if (this.commands[command.uid] === undefined) {
        this.commands[command.uid] = zeroFormCommandFactory(command.uid, command, this);
      } else {
        if (this.commands[command.uid] == null) return;
        this.commands[command.uid].reinitRawData(command);
      }
    });

    Object.values(rawFields).filter((elem) => elem.uid != null).forEach((field) => {
      if (this.fields[field.uid] === undefined) {
        this.fields[field.uid] = zeroFieldFactory(field.uid, field, this);
      } else {
        if (this.fields[field.uid] == null) return;
        this.fields[field.uid].reinitRawData(field);
      }
    });

    Object.values(rawActions).filter((elem) => elem.uid != null).forEach((action) => {
      if (this.actions[action.uid] === undefined) {
        this.actions[action.uid] = zeroActionFactory(action.uid, action, this);
      } else {
        if (this.actions[action.uid] == null) return;
        this.actions[action.uid].reinitRawData(action);
      }
    });
  }

  getfieldByUid(uid) {
    return Object.values(this.fields).find((field) => field.uid == uid);
  }

  get isValid() {
    return Object.values(this.fields).filter((field) => !field.isValid).length < 1;
  }

  get notValidList() {
    return Object.values(this.fields).filter((field) => !field.isValid).map((elem) => elem);
  }

  packToFormData(container, prefix='') {
    Object.values(this.fields).forEach((elem) => elem.packToFormData(container, prefix));
  }

  addFormFieldChangeListener(listener) {
    this.formFieldChangeListeners.push(listener);
  }

  removeFormFieldChangeListener(listener) {
    const index = this.formFieldChangeListeners.indexOf(listener);
    if (index > -1) this.formFieldChangeListeners.splice(index, 1);
  }

  formFieldUpdated(data) {
    this.formFieldChangeListeners.forEach((listener) => listener(data));
    if (this.parent != null && this.parent.formFieldUpdated != null) this.parent.formFieldUpdated({child: this, target: data.target, before: data});
  }

  addCommandListener(listener) {
    this.commandListeners.push(listener);
  }

  removeCommandListener(listener) {
    const index = this.commandListeners.indexOf(listener);
    if (index > -1) this.commandListeners.splice(index, 1);
  }

  callCommand(target) {
    if (target.command == null) return;
    this.commandListeners.forEach((listener) => listener(target));
    if (this.parent != null && this.parent.callCommand != null) this.parent.callCommand(target);
  }

  get renderData() {
    return {
      fields: Object.values(this.fields).map((field) => field.renderData),
      actions: Object.values(this.actions).map((action) => action.renderData),
    };
  }
}


export default ZeroForm;
