<template>
    <div class="flex-row">
      <LabelComponent
          class=""
          :label_text="this.label_text + (this.label_text && this.label_text.length > 0 ? ':' : '')"/>
      <ButtonComponent
        v-if="this.input_field_status != 'disabled'"
        elementType="default"
        :iconUrl="'/icons/system/magnifying-glass.svg'"
        @click_to_button="this.openSelectWindow"
      />

      <div v-if="this.selectedElemUid" class="">
        <LabelComponent
            class=""
            :label_text="this.selectedView"/>
      </div>
      <div v-else>
        <LabelComponent
            class=""
            label_text="-"/>
      </div>
    </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import NavElemSelector from './NavElemSelector.vue';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
  },
  data: () => ({
    selectedElemUid: null,
  }),
  watch: {
    selectedElemUid() {
      this.$emit('input_change', this.selectedElemUid);
    },
    initial_data() {
      this.selectedElemUid = this.initial_data;
    },
  },
  emits: ['input_change'],
  props: {
    label_text: {
      default: '',
      type: String,
      required: false,
    },
    initial_data: {
      default: null,
      required: false,
    },
    input_field_status: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    exclude_uid_list: {
      default: [],
      required: false,
    },
  },
  mounted() {
    this.selectedElemUid = this.initial_data;
  },
  computed: {
    selectedView() {
      return this.$store.getters.pageByUid(this.selectedElemUid).name;
    },
  },
  methods: {
    async openSelectWindow() {
      const newElem = await window.openWindow({
        caption: 'Выберите родителя',
        component: NavElemSelector,
        componentProps: {
          exclude_uid_list: this.exclude_uid_list,
        },
      });

      if (newElem == null) return;

      this.selectedElemUid = newElem.uid;
    },
  },
};
</script>
