<template>
  <div class="auth-page fixed-full-screen-block">
    <MainLogo class="page-logo"
              branch=""
              colorStyle="white-green"
              width="300"
              v-if="this.showLogo"
              @click_to_logo="this.toLogoClick"/>

    <div class="auth__content" ref="auth_content" :style="{
      'max-height': this.contentHeight ? this.contentHeight + 'px' : null,
      'height': this.contentHeight ? this.contentHeight + 'px' : null,
      'min-height': this.contentHeight ? this.contentHeight + 'px' : null,
      }">
        <slot></slot>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/units/logo/MainLogo.vue';

export default {
  components: {
    MainLogo,
  },
  props: {

  },
  data: ()=> ({
    contentHeight: 0,
    showLogo: true,
  }),
  watch: {
    userObj() {
      this.checkAlreadyAuth();
    },
    globalCriticalError() {
      this.checkAlreadyAuth();
    },
  },
  mounted() {
    this.checkAlreadyAuth();

    setTimeout(() => {
      this.calculateContentHeight();
    }, 1);
    window.addEventListener('resize', this.calculateContentHeight);
  },
  unmounted() {
    window.removeEventListener('resize', this.calculateContentHeight);
  },
  computed: {
    userObj() {
      return this.$store.state.user_obj;
    },
    globalCriticalError() {
      return this.$store.state.critical_error;
    },
  },
  methods: {
    calculateContentHeight() {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      this.showLogo = vh >= 350;

      const logoH = this.showLogo ? (
        this.$refs.auth_content == null ? 45 : this.$refs.auth_content.getBoundingClientRect().y
      ) : 0;

      this.contentHeight = vh - logoH;
    },
    toLogoClick() {
      this.$router.push('/');
    },
    checkAlreadyAuth() {
      if (this.globalCriticalError != null || this.$store.getters.isLoggedIn) {
        window.location.href = this.next_page ? this.next_page : '/';
      }
    },
  },
};
</script>

<style  lang="less">
.auth__content {
  width: 100%;
  padding: 0 @small-gap;
  & > * {margin: auto;}
}
</style>
