<template>
  <div v-show="!this.baseField.isHidden" class="micro-gap-column">
    <span class="error-text" v-if="this.baseField.errorText">{{ this.baseField.errorText }}</span>
    <div class="base-gap-row row-content-up">
      <h4 v-if="!this.withoutTitle">{{
        this.baseField.view + ':'
      }}</h4><span v-if="!['html', 'rhtml'].includes(this.baseField.mode)"
                   ref="based_label"
                   :style="{
                    'white-space': this.baseField.mode == 'wrap' ? 'pre-wrap' : null,
                    height: this.baseField.maxLinesCount == null ? null : (this.baseField.maxLinesCount*this.curLineHeight + 'px'),
                  }"
              >{{
        this.baseField.value
      }}</span>
      <iframe @load="this.iframeRecalc"
              style="border: 0; flex: 1; min-width: 100px;"
              :style="'height:' + this.labelHeight + 'px'"
              v-else
              :srcdoc="(this.baseField.mode == 'rhtml' ? '<style>body{margin:0;padding:0;}</style>' : '') + this.baseField.value"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data: () => ({
    labelHeight: 15,
    propReadElem: null,
  }),
  props: [
    'baseField',
    'withoutTitle',
    // 'validator',
    // 'changeHandler',
  ],
  computed: {
    curLineHeight() {
      if (this.propReadElem == null) return this.labelHeight;
      return parseFloat(window.getComputedStyle(this.propReadElem, null).getPropertyValue('--line-height').slice(0, -2));
    },
  },
  mounted() {
    setTimeout(() => this.propReadElem = this.$refs.based_label, 1);
  },
  methods: {
    // calcMaxLineCount
    iframeRecalc(event) {
      const basedDoc = event.target.contentWindow.document;
      const body = basedDoc.body;
      const html = basedDoc.documentElement;
      let curHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      this.propReadElem = event.target;

      if (this.baseField.maxLinesCount != null) {
        const lineH = this.curLineHeight;
        curHeight = Math.min(this.baseField.maxLinesCount * lineH, curHeight);
      }

      this.labelHeight = curHeight;
    },
  },
};
</script>
