import BaseObjZeroContainer from '../BaseObjZeroContainer.js';
import store from '@/store/index.js';


class PagesContainer extends BaseObjZeroContainer {
  constructor(useTypes, excludeUids) {
    super();
    this.useTypes = useTypes;
    this.excludeUids = excludeUids;
    this.refillObjects();
  }

  refillObjects() {
    this.drawObjects = store.getters.webSitePagesRoot;
  }

  researchData(searchForm) {
    return;
  }

  async getObjectsRange(startIdx, endIdx) {
    return [];
    // return this.basedValues.filter((elem, idx) => (idx >= startIdx && idx <= endIdx));
  }
}

export default PagesContainer;
