import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
import store from '@/store';

const REQUEST_LINK = '/user_pages/page_struct/';

function getWebSiteStructure(handler) {
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
  }).then(async function(response) {
    if (response.status != 200) {
      store.commit(
          'critical_error_mut',
          {
            type: 'break_pages',
            shortInfo: 'Ошибка при запросе структуры страниц!',
            fullInfo: `На сервере произошла ошибка! Возможно идут плановые работы, либо ваш случай уникален и нужно обратиться в поддержку. Статус: ${response.status} Причина: ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
          },
      );
      return;
    }

    const data = await response.json();
    // const result = [];
    // data.rows.forEach(function(rowItem) {
    //   rowItem.values.in_fast_panel = true;
    //   result.push(rowItem.values);
    // });
    handler(data);
  }).catch((error) => {
    console.log('error', error);
    store.commit(
        'critical_error_mut',
        {
          type: 'break_pages',
          shortInfo: 'Нет соединения с сервером!',
          fullInfo: 'Проверьте подключение к интернету или попробуйте зайти позже.',
        },
    );
  });
}

export default getWebSiteStructure;
