class BaseZeroAction {
  constructor(actionIdx, actionData, parent, projectData) {
    this.parent = parent;
    const curProjData = projectData == null ? (parent == null ? {} : parent.projectData) : projectData;
    this.projectData = curProjData;
    this.actionIdx = actionIdx;

    this.commandListeners = [];

    this.unpackActionData(actionData);
  }

  reinitRawData(rawActionData) {
    this.unpackActionData(rawActionData);
  }

  unpackActionData(actionData) {
    this.uid = this.uid === undefined ? actionData.uid : this.uid;
    this.type = this.type === undefined ? actionData.type : this.type;

    this.iid = actionData.iid === undefined ? this.iid : actionData.iid;

    this.command = actionData.command === undefined ? this.command : actionData.command;

    this.view = actionData.view === undefined ? this.view : actionData.view;
    this.disabled = actionData.disabled === undefined ? this.disabled : actionData.disabled;
    this.isHidden = actionData.is_hidden === undefined ? this.isHidden : actionData.is_hidden;
  }

  addCommandListener(listener) {
    this.commandListeners.push(listener);
  }

  removeCommandListener(listener) {
    const index = this.commandListeners.indexOf(listener);
    if (index > -1) this.commandListeners.splice(index, 1);
  }

  callCommand(target) {
    if (target.command == null) return;
    this.commandListeners.forEach((listener) => listener(target));
    if (this.parent != null && this.parent.callCommand != null) this.parent.callCommand(target);
  }

  call() {
    this.callCommand(this);
  }

  get projectActionComponents() {
    if (this.projectData == null) return {};
    return this.projectData.actionComponents == null ? {} : this.projectData.actionComponents;
  }

  get renderData() {
    const curComponent = this.projectActionComponents[this.type];

    if (curComponent == null) {
      console.warn('Draw component not found!', this.type, this);
      return null;
    }

    return {
      component: curComponent,
      params: this.drawParams,
    };
  }

  get drawParams() {
    return {
      baseAction: this,
    };
  }
}

export default BaseZeroAction;
