<template>
  <AuthBase>
     <div class="reset-pass-frame content-box">
      <div class="reset_pass_frame_header_text">
        <LabelComponent class="reset-pass-frame__header"
                            label_type="h1"
                            :label_text="'Сброс пароля'"/>
      </div>
        <div class="reset-pass-frame__footer">
            <ButtonComponent label="Авторизоваться"
                            elementType="default"
                            @click="this.$router.replace({path: '/login', query: {login: this.login}});"/>

            <LabelComponent class="reset-pass-frame__global-error"
                            v-show="this.global_error_text != null"
                            :label_text="this.global_error_text"/>
            <SpinnerComponent v-if="this.requesting"/>

            <template v-if="this.resetSessionKey == null && !this.end_reset">
              <InputField class="reset-pass-frame__login-field"
                          @input_change="this.setLogin"
                          :set_values="this.login"
                          name="username"
                          autocomplete="on"
                          :label_text="'Адрес электронной почты'"
                          :input_field_status="this.login_input_data.status"
                          :error_text="this.login_input_data.error_text"/>

              <ButtonComponent class="reset-pass-frame__button1"
                          label_type="button/large/medium 18"
                          elementSize="default"
                          :elementStatusModifier="this.end_reset || this.requesting ? 'disabled': undefined"
                           @click="this.resetRequest"
                           label="Сбросить пароль"/>
            </template>
            <template v-else-if="!this.end_reset">
              <InputField class="login-frame__login-field"
                      @input_change="this.setPassword"
                      name="password"
                      autocomplete="on"
                      label_text="Новый пароль"
                      :input_field_status="this.password_input_data.status"
                      :error_text="this.password_input_data.error_text"
                      type="password"/>
              <InputField class="login-frame__login-field"
                      @input_change="this.setConfirmPassword"
                      name="password"
                      autocomplete="on"
                      label_text="Подтверждение нового пароля"
                      :input_field_status="this.password_input_data.status"
                      :error_text="this.password_input_data.error_text"
                      type="password"/>

              <ButtonComponent class="reset-pass-frame__button1"
                      label_type="button/large/medium 18"
                      elementSize="default"
                      :elementStatusModifier="this.end_reset || this.requesting ? 'disabled': undefined"
                        @click="this.sendNewPassword"
                        label="Установить пароль"/>
            </template>
        </div>
    </div>
  </AuthBase>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import InputField from '@/units/forms/InputField.vue';
import AuthBase from './AuthBase';

import {resetPassRequest} from '@/assets/dataSources/auth/resetPassRequest';
import {setNewPassRequest} from '@/assets/dataSources/auth/setNewPassRequest';

export default ({
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
    AuthBase,
    SpinnerComponent,
  },
  props: {

  },
  data: ()=> ({
    global_error_text: undefined,
    login_input_data: {
      status: undefined,
      error_text: undefined,
    },
    password_input_data: {
      status: undefined,
      error_text: undefined,
    },
    login: undefined,
    password: undefined,
    confirm_password: undefined,
    requesting: false,
    end_reset: false,
  }),
  mounted() {
    this.login = this.$route.query.login;

    if (this.resetSessionKey == null) {
      window.acceptableElements.push(this.resetRequest);
    } else {
      window.acceptableElements.push(this.sendNewPassword);
    }
  },
  unmounted() {
    let index = window.acceptableElements.indexOf(this.resetRequest);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }

    index = window.acceptableElements.indexOf(this.sendNewPassword);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    resetSessionKey() {
      return this.$route.query.key;
    },
  },
  methods: {
    setLogin(data) {
      this.login=data;
    },
    setPassword(data) {
      this.password=data;
    },
    setConfirmPassword(data) {
      this.confirm_password=data;
    },
    sendNewPassword() {
      if (this.end_reset || this.requesting) return;

      if (this.password != this.confirm_password) {
        this.global_error_text = 'Пароли не совпадают!';
        return;
      }

      if (this.password == null || this.password == '') {
        this.global_error_text = 'Пароль не может быть пустым!';
        return;
      }

      const request = setNewPassRequest(this.password, this.resetSessionKey);

      this.global_error_text = null;

      this.requesting = true;

      request
          .then(async (result) => {
            if (result.status != 200) {
              throw result;
            }

            this.global_error_text = 'Пароль успешно изменен! Перейдите на авторизацию.';
            this.end_reset = true;
          }).catch(async (result) => {
            const errorText = await result.text();
            const errorStatus = result.statusText;
            const errorCode = result.status;

            this.global_error_text = 'Error: ' + errorCode + ' ' + errorText;
          }).finally(() => this.requesting = false);
    },
    resetRequest() {
      if (this.end_reset || this.requesting) return;

      const request = resetPassRequest(this.login);

      this.global_error_text = null;

      this.requesting = true;

      request
          .then(async (result) => {
            if (result.status != 200) {
              throw result;
            }

            this.global_error_text = 'Ссылка для сброса пароля выслана вам на почту';
            this.end_reset = true;
          }).catch(async (result) => {
            const errorText = await result.text();
            // const errorStatus = result.statusText;
            const errorCode = result.status;

            if (errorCode == 403) {
              this.global_error_text = 'Пользователь не найден';
              return;
            }

            this.global_error_text = 'Error: ' + errorCode + ' ' + errorText;
          }).finally(() => this.requesting = false);
    },
  },
});
</script>

<style lang="less">
.reset_pass_frame_header_text{
    max-width: 360px;
    width: 360px;
    min-width: 360px;
}
.reset-pass-frame {
  .flex(column, flex-start, center);
  width: 390px;
  box-shadow: 0 0px 15px #505050;
  padding: 20px;

  &__header {
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__global-error {
    color: red;
    text-align: center;
  }

  &__login-field {
    margin: 11px 0;
  }
  &__button1 {
    width: 75%;
    max-width: none;
    margin-top: 10px;
  }
  &__button2 {
    margin-top: 20px;
    color: hsl(0, 100%, 55%);
  }

  &__footer{
    .flex(column, center, center);
    flex-basis: 35px;
    flex-wrap: wrap;
    width: 80%;
  }
}
</style>
