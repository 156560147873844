<template>
  <div class="mob-user-menu">
    <div class="mob-user-menu__frame">
      <IconComponent
                      size="32"
                      url="/icons/system/close.svg"
                      class="mob-user-menu__close-button"
                      @click="$emit('close_modal')"/>
      <div class="mob-user-menu__preview">
        <LabelComponent class="mob-user-menu__user-name"
                        label_type="caption/big/bold 20"
                        :label_text="this.get_user_full_name"/>

      </div>
      <div class="menu-elements">
        <SeparatorComponent class="menu-elements__separator"/>
        <div v-for = "(item, idx) in this.menuItems"
             :key = "idx">
          <SeparatorComponent v-if="item.separatorTop" class="menu-elements__separator"/>
          <div class="menu-elements__element"
               @click="$emit('select_elem', idx)">
            <IconComponent size="24" :url="item.icon" class="menu-elements__icon"/>
            <LabelComponent label_type="body-large-regular-16"
                            :label_text="item.name"/>

          </div>
          <SeparatorComponent v-if="item.separatorBottom" class="menu-elements__separator"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';
import SeparatorComponent from '@/units/forms/BlockSeparator.vue';

export default {
  name: 'ProfileUserMenuMobile',
  components: {
    IconComponent,
    LabelComponent,
    SeparatorComponent,
  },
  emits: {select_elem: null, close_modal: null},
  props: ['menuItems'],
  computed: {
    get_user_info() {
      return this.$store.state.user_obj;
    },
    get_user_full_name() {
      if (
        !this.get_user_info ||
        (!this.get_user_info['first_name'] && !this.get_user_info['last_name'])
      ) return 'Неизвестное имя';
      return [this.get_user_info['first_name'], this.get_user_info['last_name']].join(' ');
    },
  },
};
</script>

<style lang="less">
@profile__mobile__text__color: @no-accent-text-color;
@profile__mobile__close-button__color: @no-accent-text-color;
@profile__mobile__item__hover__background-color: @hover-content-background;

.mob-user-menu {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 150;
  display: block;

  color: @profile__mobile__text__color;
  background-color: @base-back;
  /*Ширина профиль меню*/
  min-width: 250px;

  &__frame {
    max-width: 250px;
    margin: 30px auto;
  }

  &__preview {
    .flex(row, flex-start, center);
  }

  &__close-button {
    background-color: @profile__mobile__close-button__color;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__user-name {
    padding-left: 17px;
  }
}

.menu-elements {
  padding-top: 26px;

  &__element {
    .flex(row, flex-start, center);
    padding: 15px 0;

    &:hover {
      cursor: pointer;
      background: @profile__mobile__item__hover__background-color;
    }
  }

  &__icon {
    margin-right: 11px;
  }

  &__separator {
    margin: 5px 0;
  }
}
</style>
