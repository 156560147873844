<template>
  <div class="file-storage">
    <FileStorageLevelView v-if="this.raw_storage_data != null"
        :params="this.firstLevelParams"
        @result="this.viewEvent"
    />
  </div>
</template>

<script>
import FileStorageLevelView from './FileStorageLevelView.vue';

export default {
  components: {
    FileStorageLevelView,
  },
  data: ()=>({

  }),
  props: {
    files_downloader: {
      default: (filePath) => '',
      required: false,
    },
    raw_storage_data: {
      default: null,
      required: false,
    },
    can_edit_struct: {
      default: false,
      required: false,
    },
  },
  mounted() {

  },
  watch: {

  },
  computed: {
    firstLevelParams() {
      return {
        drawStruct: this.raw_storage_data,
        curPath: '',
        canEditStruct: this.can_edit_struct,
        filesDownloader: this.files_downloader,
        selfComponent: FileStorageLevelView,
      };
    },
  },
  emits: ['requireReload'],
  methods: {
    viewEvent(result) {
      if (result == 'upd_struct') {
        this.$emit('requireReload');
      }
    },
  },
};
</script>
