<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <div v-for="field, fieldName in this.form_data" :key="fieldName" class="base-gap-row">
      <LabelComponent :label_text="field.label + ':'" :is_no_accent="true"/>
      <LabelComponent :label_text="field.val" :is_no_accent="true"/>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel';

export default ({
  emits: [],
  components: {
    LabelComponent,
  },
  props: {
    raw_fields: {
      required: true,
      type: Array,
    },
    verticalMode: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    form_data: [],
  }),
  mounted() {
    this.initFromRaw();
  },
  watch: {
    raw_fields() {
      this.initFromRaw();
    },
  },
  computed: {

  },
  methods: {
    initFromRaw() {
      if (this.raw_fields == null) {
        return;
      }

      this.form_data = [];

      this.raw_fields.filter((field) => !field.is_hidden).forEach((field) => {
        const newFieldData = {
          label: field.view,
          val: field.value,
        };

        if (field.type == 'link') {
          newFieldData.val = field.value == null ? '-' : field.value.view;
        }

        if (field.type == 'link_list') {
          if (field.value == null) {
            newFieldData.val = '-';
          } else {
            const maxElemsCount = 2;

            newFieldData.val = field.value.slice(0, maxElemsCount).map((curVal) => String(curVal.view)).join(', ');

            if (field.value.length > maxElemsCount) {
              newFieldData.val += ' и ещё ' + (field.value.length - maxElemsCount);
            }
          }
        }

        if (field.type == 'dateperiod' || field.type == 'period_date') {
          newFieldData.val = field.value == null ? '-' : 'с ' + new FixDate(field.value.start_date).toLocaleDateString() + ' по ' + new FixDate(field.value.end_date).toLocaleDateString();
        }
        this.form_data.push(newFieldData);
      });
    },
  },
});
</script>
