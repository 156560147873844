<template>
  <div>
    <StdSpinner v-if="this.isLoading"/>
    <LabelComponent v-if="this.listRequestError != null" :label_text="this.listRequestError" style="color: red;"/>
    <ButtonComponent elementType="default" v-for="file in filesList" :key="file"
                     :label="file.view"
                     @click="this.downloadFile(file)"/>
  </div>
</template>

<script type="text/javascript">
import getPreviewReportFileList from '@/assets/dataSources/reports/getPreviewReportFileList.js';
import downloadPreviewReportFile from '@/assets/dataSources/reports/downloadPreviewReportFile.js';
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import StdSpinner from '@/units/logo/StdSpinner.vue';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
    StdSpinner,
  },
  data: () => ({
    filesList: [],
    isLoading: false,
    requestVersion: 0,
    listRequestError: null,
  }),
  props: {
    listPath: {
      default: '',
      required: false,
    },
  },
  mounted() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.requestVersion += 1;
      this.filesList = [];
      this.isLoading = true;

      const requestReportFormVersion = this.requestVersion;

      getPreviewReportFileList(this.listPath, this.dataSourceUid, (result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        this.isLoading = false;

        if (result._error == true) {
          if (result.status == null) this.listRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.listRequestError = `${result.text} Статус: ${result.status}`;
          return;
        }

        this.filesList = result;
      });
    },
    downloadFile(file) {
      // console.log('Download file', file.uid);
      downloadPreviewReportFile(file.uid, this.dataSourceUid, () => {});
    },
  },
  computed: {
    dataSourceUid() {
      return this.$store.state.current_page_info?.ds_uid;
    },
  },
};
</script>
