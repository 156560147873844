<template>
    <InputFieldTemplate @focusChange="'this.setFocus'"
                        v-show="!this.baseField.isHidden"
                        :disabled="this.baseField.disabled"
                        :hideData="this.baseField.value == null"
                        :is_required="this.baseField.required"
                        :title="this.baseField.view"
                        :error="this.baseField.errorText">
      <DateInput
                  @dateChange="this.setNewDate"
                  :base_day="this.day"
                  :base_month="this.month"
                  :base_year="this.year"
      />
    </InputFieldTemplate>
</template>

<script>
import InputFieldTemplate from './InputFieldTemplate.vue';
import DateInput from '../units/DateInput.vue';

export default {
  components: {
    InputFieldTemplate,
    DateInput,
  },
  data: () => ({

  }),
  props: [
    'baseField',
  ],
  computed: {
    day() {
      return this.baseField.value == null ? null : this.baseField.value.day;
    },
    month() {
      return this.baseField.value == null ? null : this.baseField.value.month;
    },
    year() {
      return this.baseField.value == null ? null : this.baseField.value.year;
    },
  },
  mounted() {

  },
  methods: {
    setNewDate(event) {
      if (event == null) {
        this.baseField.setValue(null);
      } else {
        this.baseField.setValue(new FixDate(event.year, event.month, event.day));
      }
    },
  },
};
</script>
