<template>
    <component v-if="this.drawComponent != null"
        :is="this.drawComponent"
        :draw_info="this.drawComponentInfo"
        :draw_data="this.drawData"
        @next_select="this.openNextLevel"
        @download_files="this.openFilesList"
        >
    </component>
    <LabelComponent v-else :label_text="`Неверный тип компонента отрисовки: ${this.drawComponentInfo.type}`"/>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import ReportFilesList from './views/ReportFilesList.vue';

import uniTable from './views/uni_table.vue';
import {markRaw} from '@vue/reactivity';


const accordanceViews={
  'table': uniTable,
  // 'pie_chart': uniPieChart,
  // 'sliced_table': uniSlicedTable,
  // 'map': uniMap,
  // 'redirect_link': uniOpenLinkAction,
  // 'download_file': uniDownloadFileAction,
  // 'card_list': uniCardList,
  // 'external_form': uniExternalForm,
  // 'form': uniForm,
};

export default {
  components: {
    LabelComponent,
  },
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  data: () => ({

  }),
  emits: ['result'],
  watch: {

  },
  mounted() {

  },
  unmounted() {

  },
  methods: {
    openNextLevel(nextView, selectIdx) {
      window.openWindow({
        caption: nextView.modalCaption,
        type: 'bottom',
        component: this.selfComponent,
        componentProps: {
          drawStruct: nextView,
          selfComponent: this.selfComponent,
        },
      });
    },
    openFilesList({path, title}) {
      // console.log('DOWNLOAD FILES', path, title);
      window.openWindow({
        caption: title,
        // type: 'bottom',
        component: ReportFilesList,
        innerProps: {listPath: path},
      });
    },
  },
  computed: {
    selfComponent() {
      return this.params.selfComponent;
    },
    drawStruct() {
      return this.params.drawStruct;
    },
    drawComponent() {
      return markRaw(accordanceViews[this.drawComponentInfo.type]);
    },
    drawComponentInfo() {
      return this.drawStruct.next_type_data;
    },
    drawData() {
      return this.drawStruct.next_level;
    },
  },
};
</script>
