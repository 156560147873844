import BaseZeroField from './BaseZeroField.js';
import ZeroForm from '@/zeroForms/behaviour/ZeroForm.js';


class ExTableZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);

    // if (fieldData.project_data != null) this.projectData = fieldData.project_data;

    this.rawData = fieldData.form;

    this.forChildProjData = Object.fromEntries(Object.keys(this.projectData).map((key) => [key, this.projectData[key]]));
    this.forChildProjData.inputComponents = this.forChildProjData.exTableInputComponents;

    if (this.templateForm == null) this.templateForm = new ZeroForm('base', this.rawData, this);
    else this.templateForm.reinitRawData(this.rawData);
  }

  _defaultValueValidator(newVal) {
    const ans = super._defaultValueValidator(newVal);
    if (ans != null) return ans;

    if ((newVal == null || newVal.length < 1) && this.required) return 'Это обязательное поле!';
  }

  addRow() {
    const newVal = this.value == null ? [] : this.value;

    newVal.push(new ZeroForm('_' + newVal.length, this.rawData, this, this.forChildProjData));
    this.setValue(newVal);
  }

  removeRow(idx) {
    const newVal = this.value == null ? [] : this.value;
    if (idx > -1 && idx < newVal.length) {
      newVal.splice(idx, 1);
      this.setValue(newVal);
    }
  }

  get isValid() {
    return this.errorText == null && (this.value == null ? [] : this.value).filter((row) => !row.isValid).length < 1;
  }

  get formDataValue() {
    throw new Error('not implemented!!!');
  }
}

export default ExTableZeroField;
