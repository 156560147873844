import BaseZeroField from './BaseZeroField.js';


class DateZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);
    this.min = fieldData.min === undefined && this.min != null ? this.min : (fieldData.min == null ? -Infinity : fieldData.min);
    this.max = fieldData.max === undefined && this.max != null ? this.max : (fieldData.max == null ? Infinity : fieldData.max);
    if (this.min > this.max) this.max = this.min;
  }

  get formDataValue() {
    throw new Error('not implemented!!!');
  }
}

export default DateZeroField;
