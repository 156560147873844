<template>
  <ButtonComponent
                      elementType="default"
                      :iconUrl="this.baseField.value.uid" iconSize="27"
                      class="icon-select-button"
                      @click="this.reselectObject()"
      />
</template>

<script>
import InternalIconSelector from './InternalIconSelector.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    ButtonComponent,
  },
  data: () => ({

  }),
  props: [
    'baseField',
  ],
  computed: {
    objSelector() {
      return this.baseField.renderObjSelector;
    },
  },
  mounted() {

  },
  methods: {
    async reselectObject() {
      const result = await window.openWindow({
        caption: 'Выбор Иконки',
        component: InternalIconSelector,
        componentProps: {
          baseField: this.baseField,
        },
      });

      if (result !== undefined) {
        this.baseField.setValue(result);
      }
    },
  },
};
</script>
