export default async () => {
  window.WORK_IN_IOS_APPLICATION = window.webkit != null &&
                                 window.webkit.messageHandlers != null &&
                                 window.webkit.messageHandlers.jsHandler != null;
  window.WORK_IN_ANDROID_APPLICATION = window.Android != null &&
                                     window.Android.additionalFunction != null;

  window.WORK_IN_APPLICATION = window.WORK_IN_IOS_APPLICATION || window.WORK_IN_ANDROID_APPLICATION;

  if (window.WORK_IN_APPLICATION) {
    let initResolver = null;
    const initAwaiter = new Promise((_initResolver) => initResolver = _initResolver);
    const initTimeout = setTimeout(() => initResolver(), 5000);

    window.applicationCallback = {};
    window.availableApplicationFunctions = [];
    window.emptyCallback = () => {};

    window.hasFunctions = (checkFunctions) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const funcIdx in checkFunctions) {
        if (
          window.availableApplicationFunctions.find(
              (item) => item === checkFunctions[funcIdx],
          ) == null
        ) {
          return false;
        }
      }

      return true;
    };

    if (window.WORK_IN_IOS_APPLICATION) {
      window.sendMessageInApplication = (data) => {
        if (data.callback == null) {
          // eslint-disable-next-line no-param-reassign
          data.callback = 'window.emptyCallback';
        }
        window.webkit.messageHandlers.jsHandler.postMessage(data);
      };
    }

    if (window.WORK_IN_ANDROID_APPLICATION) {
      window.sendMessageInApplication = (data) => {
        if (data.callback == null) {
          // eslint-disable-next-line no-param-reassign
          data.callback = 'window.emptyCallback';
        }
        window.Android.additionalFunction(JSON.stringify(data));
      };
    }

    window.applicationCallback.availableTypes = (types) => {
      window.availableApplicationFunctions = types;

      if (window.hasFunctions(['check_permissions'])) {
        window.sendMessageInApplication({type: 'check_permissions'});
      }

      if (window.hasFunctions(['get_app_version'])) {
        // console.log('CHECK');
        window.sendMessageInApplication({
          type: 'get_app_version',
          callback: 'window.applicationCallback.setApplicationVersion',
        });
      } else {
        clearTimeout(initTimeout);
        initResolver();
      }
    };

    window.APPLICATION_PROJECT_VERSION = -1;
    window.APPLICATION_MARKET_VERSION = -1;

    // eslint-disable-next-line camelcase
    window.applicationCallback.setApplicationVersion = ({project_version, marketing_version}) => {
      // console.log('projectVersion, marketingVersion', project_version, marketing_version);
      try {
        window.APPLICATION_PROJECT_VERSION = parseFloat(project_version);
        window.APPLICATION_MARKET_VERSION = parseFloat(marketing_version);
      } catch (err) {
        console.log('Parse version error! ', err);
      }

      clearTimeout(initTimeout);
      initResolver();
    };

    // window.CallApplicationEvent = (platform, type, data) => {
    //   alert(`${platform} ${type} ${data.token}`);
    // };

    window.sendMessageInApplication({
      type: 'available_types',
      callback: 'window.applicationCallback.availableTypes',
    });
    await initAwaiter;
  }
};

export function tokenRegistrator() {
  if (!window.WORK_IN_APPLICATION) return;

  window.applicationCallback.setPushToken = (status, key, value) => {
    if (value == null || value === '') return;

    let appType = null;
    if (window.WORK_IN_IOS_APPLICATION) appType = 'APNS';
    if (window.WORK_IN_ANDROID_APPLICATION) appType = 'FBS';
    if (appType == null) return;

    const body = new FormData();
    body.append('push_system', appType);
    body.append('token', value);

    fetch(
        ROOT_URL+'/push_system/register_token/',
        {
          method: 'POST',
          credentials: 'include',
          body,
        },
    ).catch(() => {});
  };

  window.sendMessageInApplication({
    type: 'get_saved_string',
    key: '__push_token__',
    callback: 'window.applicationCallback.setPushToken',
  });
}
