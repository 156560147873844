<template>
  <div class="base-gap-column">
    <component
               v-for="field in drawData.fields"
               :key="field"
               :is="field.component"
               v-bind="field.params"
    />

    <component
               v-for="action in drawData.actions"
               :key="action"
               :is="action.component"
               v-bind="action.params"
    />
  </div>
</template>


<script>
import ZeroForm from '@/zeroForms/behaviour/ZeroForm.js';

import LabelZeroField from './zeroFields/LabelZeroField.vue';
import NumberZeroField from './zeroFields/NumberZeroField.vue';
import TextZeroField from './zeroFields/TextZeroField.vue';
import BooleanZeroField from './zeroFields/BooleanZeroField.vue';
import DateZeroField from './zeroFields/DateZeroField.vue';
import ObjSelectZeroField from './zeroFields/ObjSelectZeroField.vue';

import ExTableZeroField from './zeroFields/ExTableZeroField.vue';

import SubmitZeroAction from './zeroActions/SubmitZeroAction.vue';

import ExCellLabelZeroField from './zeroFields/exCellFields/LabelZeroField.vue';
import ExCellNumberZeroField from './zeroFields/exCellFields/NumberZeroField.vue';
import ExCellTextZeroField from './zeroFields/exCellFields/TextZeroField.vue';
import ExCellBooleanZeroField from './zeroFields/exCellFields/BooleanZeroField.vue';
import ExCellDateZeroField from './zeroFields/exCellFields/DateZeroField.vue';
import ExObjSelectZeroField from './zeroFields/exCellFields/ObjSelectZeroField.vue';

import DefaultObjField from './zeroFields/ObjSelectors/DefaultObjField.vue';
import InternalIconField from './zeroFields/ObjSelectors/InternalIconField.vue';
import InternalPageField from './zeroFields/ObjSelectors/InternalPageField.vue';

import {markRaw} from 'vue';
// import StaticObjZeroContainer from '@/zeroForms/behaviour/objContainers/StaticObjZeroContainer';

const projData = {
  inputComponents: {
    'label': markRaw(LabelZeroField),
    'number': markRaw(NumberZeroField),
    'text': markRaw(TextZeroField),
    'boolean': markRaw(BooleanZeroField),
    'date': markRaw(DateZeroField),
    'entity_selector': markRaw(ObjSelectZeroField),

    'ex_table': markRaw(ExTableZeroField),
  },
  exTableInputComponents: {
    'label': markRaw(ExCellLabelZeroField),
    'number': markRaw(ExCellNumberZeroField),
    'text': markRaw(ExCellTextZeroField),
    'boolean': markRaw(ExCellBooleanZeroField),
    'date': markRaw(ExCellDateZeroField),
    'entity_selector': markRaw(ExObjSelectZeroField),

    'ex_table': markRaw(ExTableZeroField),
  },
  actionComponents: {
    'submit': markRaw(SubmitZeroAction),
  },
  objSelectorComponents: {
    'default': markRaw(DefaultObjField),
    '_internal.icon': markRaw(InternalIconField),
    '_internal.page': markRaw(InternalPageField),
  },
};

export default {
  components: {

  },
  emits: ['callCommand'],
  props: [
    'formData',
  ],
  data: () => ({
    formObj: null,
    curUseState: false,
    curBasedPageValue: null,
  }),
  computed: {
    drawData() {
      if (this.formObj == null) return {};
      return this.formObj.renderData;
    },
  },
  watch: {
    formData() {
      this.formObj.reinitRawData(this.formData);
    },
  },
  mounted() {
    if (this.formData == null) return;
    this.formObj = new ZeroForm('default',
        this.formData,
        null,
        projData,
    );

    this.formObj.addCommandListener(this.callCommand);
  },
  methods: {
    callCommand(target) {
      this.$emit('callCommand', this.formObj, target);
    },
  },
};
</script>
