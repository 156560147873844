/* eslint-disable no-unused-vars */
import baseClasses from '@/assets/less/base_classes.less';

import baseTheme from '@/assets/less/themes/base_theme.less';
import lightTheme from '@/assets/less/themes/light_theme.less';
import darkTheme from '@/assets/less/themes/dark_theme.less';
import oldTheme from '@/assets/less/themes/old_theme.less';
import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import mobileInjection from './ProjectInjections/MobileAppInjection';
import pageBehaviourInjection from './ProjectInjections/PageBehaviourInjection';
import baseJsCacheControl from './ProjectInjections/BaseJsCacheControl';
import winSizeControlInjection from './ProjectInjections/WinSizeControlInjection.js';

// import components from '@/assets/less/components.less';

async function run() {
  await mobileInjection();
  pageBehaviourInjection();
  baseJsCacheControl();
  winSizeControlInjection(store);

  createApp(App).use(store).use(router).mount('#app');
}

run();
