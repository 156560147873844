<template>
  <div class="left-block-no-resize">
    <div class="left-block-wrapper">
      <div class="left-content">
        <div class="search-line" v-show="this.$store.getters.isUserOwner">
          <transition name="left-tools-line__fade" appear>
            <div v-show="this.menu_mode == 'default' && this.$store.getters.isUserOwner" class="left-tools-line">
              <ButtonComponent
                                iconUrl="/icons/system/folder_plus.svg"
                                elementType="default"
                                iconSize="28"
                                :iconClass="this.isMobWidth ? 'icon-base-color' : 'icon-over-transparency-color'"
                                @click="this.openPageForm"
                              />

              <ButtonComponent
                                iconUrl="/icons/system/magnifying-glass.svg"
                                elementType="default"
                                iconSize="28"
                                :iconClass="this.isMobWidth ? 'icon-base-color' : 'icon-over-transparency-color'"
                                @click="this.menu_mode = 'search'"
                              />
            </div>
          </transition>

          <transition name="search-field__fade">
            <div v-show="this.menu_mode == 'search'" class="search-field">
              <SearchTextField
                              ref="searchField"
                              @search="this.startSearch"
                              @close="this.menu_mode = 'default'"
                              :closeButton="true"
              />
            </div>
          </transition>
        </div>

        <transition name="default-nav-struct__fade">
          <div v-show="this.menu_mode == 'default'" class="default-nav-struct">
            <StructNode class="content-background" :levelData="this.getPagesStruct"/>
          </div>
        </transition>

        <transition name="search-nav-struct__fade">
          <div v-show="this.menu_mode == 'search'" class="search-nav-struct">
            <FullSearcher :searchRequest="this.search_request" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import SearchTextField from '@/units/forms/SearchTextField.vue';
import StructNode from './StructNode.vue';
import FullSearcher from './FullSearcher.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import PageForm from '@/windows/PageForm.vue';
import isMobileMixin from '@/assets/mixins/isMobileMixin.js';

export default ({
  name: 'LeftMenu',
  components: {
    StructNode,
    ButtonComponent,
    SearchTextField,
    FullSearcher,
  },
  mixins: [isMobileMixin],
  data: () => ({
    menu_mode: 'default',
    search_request: null,
  }),
  watch: {
    menu_mode() {
      if (this.menu_mode == 'search') {
        setTimeout(() => this.$refs.searchField.setFocus(), 300);
      }
    },
  },
  mounted() {
    window.escapeableElements.push(this.closeSearch);
  },
  unmounted() {
    const index = window.escapeableElements.indexOf(this.closeSearch);
    if (index > -1) window.escapeableElements.splice(index, 1);
  },
  computed: {
    getPagesStruct() {
      return this.$store.getters.webSitePagesRoot;
    },
  },
  methods: {
    openPageForm() {
      window.openWindow({
        caption: 'Добавление новой группы',
        component: PageForm,
        componentProps: {
          page_type: 'group',
          initial_name: 'Новая папка',
          initial_icon: '/icons/reports/light/folder.svg',
        },
      });
    },
    closeSearch() {
      if (this.menu_mode == 'default') {
        return true;
      }
      this.$refs.searchField.close();
    },
    startSearch(searchData) {
      this.search_request = null;
      this.$nextTick(() => {
        this.search_request = searchData;
      });
    },
  },
});
</script>

<style lang="less">
@anim-speed: 0.3s;

@search-line-height: 44px;

.search-nav-struct {
  position: absolute;
  top: @search-line-height;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  & > * {
    height: fit-content;
    max-height: 100%;
    overflow-y: auto;
    padding: @small-gap !important;
  }

  &__fade-enter-active,
  &__fade-leave-active {
    position: absolute;
    right: 0;
    width: 100%;
    transition: right @anim-speed;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    position: absolute;
    right: -100%;
    width: 100%;
  }
}

.default-nav-struct {
  // position: absolute;
  // top: @search-line-height;
  // bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & > * {
    height: fit-content;
    max-height: 100%;
    overflow-y: auto;
    // padding: 0 !important;
  }

  &__fade-enter-active,
  &__fade-leave-active {
    position: absolute;
    top: @search-line-height;
    left: 0;
    width: 100%;
    height: auto;
    transition: left @anim-speed;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    position: absolute;
    top: @search-line-height;
    left: -100%;
    width: 100%;
    height: auto;
  }
}

.left-tools-line {
  padding: 5px;
  .flex(row, space-between, flex-end);

  flex: 1;

  &__fade-enter-active,
  &__fade-leave-active {
    // position: absolute;
    transition: opacity @anim-speed;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    // position: absolute;
    opacity: 0;
  }
}

.search-field {
  // margin: 0 16px 0 16px;
  width: 100%;

  padding: 5px;

  // position: relative;
  // right: 0;

  &__fade-enter-active,
  &__fade-leave-active {
    position: absolute;
    right: 0;
    transition: right @anim-speed;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    position: absolute;
    right: -100%;
  }
}

.search-line {
  // margin-left: auto;
  // width: 100%;
  max-width: 100%;
  // margin: 5px;
  height: @search-line-height;
  .flex(row, flex-end, flex-end);
}

.left-block-no-resize{
  .flex(column, flex-start, flex-start);
  position: relative;
  flex-grow: 1;

  width: 100%;

  overflow: hidden;


  .left-block-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: hidden;
    // overflow-x: hidden;
    // overflow-y: auto;
  }
}

.left-content {
  overflow: hidden;
  // overflow-x: hidden;
  // overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.empty-menu-helper {
  padding: 13px;
}
</style>
