import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/iapi_report/download_preview_report_file/?path=';

function downloadPreviewReportFile(filePath, dsUid, handler) {
  window.open(ROOT_URL + REQUEST_LINK + filePath + '&ds_uid=' + dsUid, '_blank').focus();
  handler('ok');
}

export default downloadPreviewReportFile;
