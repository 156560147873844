<template>
  <div class="icon-list">
    <IconComponent v-for="item in this.iconList" :key="item"
                    @click="this.selectIcon({uid: '/icons/reports/' + item})"
                    class="icon-select-button"
                    :url="'/icons/reports/' + item" size="27"
                    />
  </div>
</template>

<script type="text/javascript">
import IconComponent from '@/units/RichIcon.vue';
import reportIconList from '@/assets/reportIconList.js';

export default {
  components: {
    IconComponent,
  },
  data: () => ({

  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    selectIcon(elem) {
      this.$emit('result', elem);
    },
  },
  computed: {
    iconList() {
      return reportIconList;
    },
  },
};
</script>

<style lang="less">

.icon-list {
  .flex(row, start, center);
  flex-wrap: wrap;
  max-width: 385px;

  & > * {
    margin-right: 21px;
    margin-top: 21px;
  }
}

</style>
