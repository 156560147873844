import BaseZeroField from './BaseZeroField.js';


class ObjSelectZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);
    this.objType = fieldData.obj_type === undefined ? this.objType : fieldData.obj_type;
    this.objContainer = fieldData.obj_container === undefined ? this.objContainer : fieldData.obj_container;
  }

  _defaultValueValidator(newVal) {
    const ans = super._defaultValueValidator(newVal);
    if (ans != null) return ans;

    return null;
  }

  get projectObjSelectorComponents() {
    if (this.projectData == null) return {};
    return this.projectData.objSelectorComponents == null ? {} : this.projectData.objSelectorComponents;
  }

  get renderObjSelector() {
    let ans = this.projectObjSelectorComponents[this.objType];
    ans = ans == null ? this.projectObjSelectorComponents['default'] : ans;

    if (ans == null) {
      console.warn('ObjSelector component not found!', this.objType, this);
      return null;
    }

    return {
      component: ans,
      params: this.objSelectorDrawParams,
    };
  }

  get objSelectorDrawParams() {
    return {
      baseField: this,
    };
  }

  get formDataValue() {
    return String(this.value.uid);
  }

  normaliseValue(val) {
    if (val == null) return null;
    if (val.uid === undefined) {
      return {
        uid: String(val),
      };
    }

    return val;
  }
}

export default ObjSelectZeroField;
