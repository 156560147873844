<template>
  <div
      class="dropdown-selector-items"
      :style="this.itemsHeight == null ? '' : `max-height: ${this.itemsHeight}px`"
      >

    <div class="dropdown-selector-items-content" ref="items_wraper">
      <!-- <div> -->
        <div v-if="this.params.baseField.nullable && !this.params.baseField.required" @click="this.selectObj(null)">__пусто__</div>
        <div v-for="elem in this.drawObjs" :key="elem" @click="this.selectObj(elem)">
          {{ elem.view }}
        </div>
      <!-- </div> -->
      <div v-if="this.isLoading">Loading...</div>
      <!-- <LabelComponent v-if="this.isFavoritable && Object.keys(this.favorites).length" label_text="Избранное:"/>

      <template v-for="elem, eIdx in this.unitedDrawValues" :key="eIdx">
        <SeparatorComponent
                            v-if="this.isFavoritable && eIdx == Object.keys(this.favorites).length"
                            style="margin-bottom: 24px;"/>

        <div class="dropdown-selector-item"
              @click="this.setElemSelected(elem, !elem.selected)">
          <CheckBoxComponent v-if="this.isMultiSelect"
                            :label_text_right="elem.view"
                            :init_value="elem.selected"
                            />
          <LabelComponent v-else
                          :label_text="elem.view"/>
          <Icon class="favorite-button" :url="'/icons/system/' + (elem.isFavorite ? 'favorite_full.svg' : 'favorite_empty.svg')"
                sizeHeight="20"
                sizeWidth="20"
                v-if="this.isFavoritable"
                @click.stop="this.setFavorite(elem, !elem.isFavorite)"
          />
        </div>
      </template>

      <LabelComponent v-if="!this.drawValues || this.drawValues.length < 1"
                          label_text="<элементов нет>"
                          />

      <SpinnerComponent v-if="this.isLoading" :width="7" size="35"/> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  emits: ['result'],
  data: () => ({
    items_wraper: null,
    minLineHeight: 18,
    isLoading: false,
    lastIdx: -1,
  }),
  props: [
    'params',
  ],
  computed: {
    maxHeight() {
      const bodyRect = document.getElementsByTagName('body')[0].getBoundingClientRect();

      const reqSize = bodyRect.bottom - (bodyRect.height / 100) - 35;

      return reqSize;
    },
    itemsHeight() {
      return Math.min(Math.max(((this.drawObjs.length - 2) * this.minLineHeight), 80), this.maxHeight);
    },
    pageSize() {
      return Math.max(parseInt(this.itemsHeight / this.minLineHeight) * 2, 15) + 1;
    },
    drawObjs() {
      return this.params.baseField.objContainer.drawObjects;
    },
  },
  mounted() {
    window.addEventListener('resize', this.calcItemListHeight);

    this.lastIdx = this.drawObjs.length - 1;

    setTimeout(() => {
      this.items_wraper = this.$refs.items_wraper;
      this.items_wraper.parentNode.addEventListener('scroll', this.checkScroll);

      if (this.lastIdx < 0) this.addPage();
    }, 1);
  },
  methods: {
    selectObj(obj) {
      this.$emit('result', obj);
    },
    checkScroll(event) {
      if (this.isLoading) return;

      const itemsWrapperParentRect = this.items_wraper.parentNode.getBoundingClientRect();
      itemsWrapperParentRect.height;

      if (event.target.scrollHeight - (itemsWrapperParentRect.height + event.target.scrollTop) < this.minLineHeight * 5) {
        this.addPage();
      }
    },
    async addPage() {
      if (this.isLoading || this.lastIdx > this.drawObjs.length) return;
      this.isLoading = true;

      const startPos = this.lastIdx + 1;
      const curPageSize = this.pageSize;
      const newPageId = parseInt(startPos / curPageSize);

      await this.params.baseField.objContainer.uploadPage(newPageId, curPageSize);

      this.lastIdx = newPageId * curPageSize + curPageSize - 1;

      this.isLoading = false;
    },
  },
};
</script>

<style lang="less">
.dropdown-selector {
  &-items {
    overflow: auto;
  }

  &-item {
    .flex(row, space-between, space-between);

    width: 100%;
    padding: 4px 15px;

    &:hover {
      background: @accent-text-color;
      cursor: pointer;
    }
  }

  .selected-item {
    color: @accent-text-color;
  }
}

</style>
