import SubmitZeroAction from './actions/SubmitZeroAction.js';


const actionsDict = {
  'submit': SubmitZeroAction,
};


function zeroActionFactory(actionIdx, actionData, parent) {
  const CurAction = actionsDict[actionData.type];

  if (CurAction == null) {
    console.warn('For action data not found action!', actionData);
    return null;
  }

  return new CurAction(actionIdx, actionData, parent);
}


export default zeroActionFactory;
