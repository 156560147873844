import {ROOT_URL} from '@/store';
import JsEncrypt from 'jsencrypt';

export function resetPassRequest(login) {
  const ans = fetch(ROOT_URL+'/auth/get_crypt_session/', {
    method: 'GET',
  }).then(async function(result) {
    const jsonData = await result.json();

    const cryptSession = jsonData.session;
    const cryptPublicKey = jsonData.public_key;

    const cryptData = {
      login: login,
    };

    const en = new JsEncrypt();
    en.setPublicKey(cryptPublicKey);
    const encrypted = en.encrypt(JSON.stringify(cryptData));

    return fetch(
        ROOT_URL+'/auth/request_reset_password/',
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'ENCRYPTED-DATA': encrypted,
            'CRYPT-SESSION': cryptSession,
          },
        },
    );
  });

  return ans;
}
