<template>
    <div class="icon" v-if="type === 'svg'"
         @click="$emit('icon_click')"
         :style="this.get_styles"
         :class="this.url?'icon-style-color':'default-icon'"/>
    <img v-else :style="get_styles" :src="get_styles.src">
</template>

<script type="text/javascript">

/*
 * Поле вывода строки текста.
 * По запросу меняет свою высоту и ширину.
 * При сборке данных формы отдаёт свое значение, если разрешено сервером.
 * Настраивается на сервере
 */

export default {
  props: {
    size: {
      // type: String,
      required: false,
    },
    sizeHeight: {
      // type: String,
      default: '24',
    },
    sizeWidth: {
      // type: String,
      default: '24',
    },
    color: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      required: false,
    },
    padding: {
      type: String,
      default: '0',
    },
    type: {
      default: 'svg',
      type: String,
      required: false,
    },

  },
  emits: {icon_click: null},
  computed: {
    get_styles() {
      const ans = {
        'src': this.type != 'svg' ? this.url : null,
        'mask': this.url ? `url(${this.url}) no-repeat center / contain` : null,
        '-webkit-mask': this.url ? `url(${this.url}) no-repeat center / contain` : null,
        'min-width': `${this.size || this.sizeWidth}px`,
        'max-width': `${this.size || this.sizeWidth}px`,
        'min-height': `${this.size || this.sizeHeight}px`,
        'max-height': `${this.size || this.sizeHeight}px`,
      };

      if (this.color) {
        if (!this.url) {
          ans['border-color'] = this.color;
        } else {
          ans.fill = this.color;
          ans.stroke = this.color;
          ans.background = this.color;
        }
      }

      return ans;
    },
  },

};
</script>
