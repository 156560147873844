import BaseZeroField from './BaseZeroField.js';


class BooleanZeroField extends BaseZeroField {
  unpackFieldData(fieldData) {
    super.unpackFieldData(fieldData);
    this.isCheckbox = fieldData.checkbox === undefined ? this.isCheckbox : fieldData.checkbox == true;
  }

  normaliseValue(val) {
    return val == true;
  }

  get formDataValue() {
    return this.value == true ? '1' : '0';
  }

  // setValue(newVal) {
  //     if (newVal < this.min) newVal = this.min;
  //     if (newVal > this.max) newVal = this.max;
  //     if (!this.isFloat) newVal = parseInt(newVal);
  //     super.setValue(newVal);
  // }
}

export default BooleanZeroField;
