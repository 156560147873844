<template>
  <transition name="slow-opacity-fade" appear>
    <div class="block-wrap-screen fixed-full-screen-block" v-show="!this.is_disabled">
      <transition name="page-logo" appear>
        <MainLogo class="page-logo"
                  branch=""
                  v-show="!this.is_disabled"
                  colorStyle="white-green"
                  width="300"
                  />
      </transition>

      <div
            class="loading-group flex-row top-padding-gap"
            :class="{'loading-group__enabled': this.display_load_status && !this.hideLoadStatus}">
        <SpinnerComponent v-if="!this.loadFinish && this.getCriticalError == null" :width="5" size="27"/>
        <IconComponent v-else-if="this.loadFinish" :url="'/icons/system/check.svg'" size="27"/>
        <IconComponent v-else color="#eb5050" :url="'/icons/system/close.svg'" size="27"/>
        <LabelComponent :label_text="this.loadingText" label_type="headline/h3/medium 18"/>
      </div>

      <div class="hello-block margin-center-block" v-if="this.getUserInfo != null">
        <template v-if="!this.getUserInfo.is_anonymous">
          <LabelComponent
                          :label_text="`Добро пожаловать, ${this.getUserInfo.first_name}!`"
                          label_type="headline/h2/bold 22"
                        />
        </template>
        <template v-else>
          <LabelComponent class="double-top-padding-gap"
                          label_text="Приветствуем на портале!"
                          label_type="headline/h2/bold 22"
                        />
          <LabelComponent class="double-top-padding-gap"
                          label_text="Для авторизации или регистрации начните работу"
                          label_type="headline/h2/bold 18"
                        />
        </template>
      </div>

      <div class="block-page-watch">
        {{ this.getTime }}
      </div>

      <transition name="start-work-button__fade" appear>
        <ButtonComponent  elementSize="big"
                          class="start-work-button"
                          label="Начать работу"
                          v-show="this.loadFinish && !this.is_disabled"
                          @click="this.clickStart"
                        />
      </transition>
    </div>
  </transition>
</template>

<script>
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import MainLogo from '@/units/logo/MainLogo.vue';
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import IconComponent from '@/units/RichIcon.vue';

export default {
  components: {
    SpinnerComponent,
    MainLogo,
    LabelComponent,
    ButtonComponent,
    IconComponent,
  },
  emits: {'start_work_click': null},
  data: () => ({
    current_time: new FixDate(),
    timer_id: null,
    hideLoadStatus: true,
  }),
  props: {
    display_load_status: {
      default: false,
      required: false,
    },
    is_disabled: {
      default: false,
      required: false,
    },
  },
  created() {

  },
  watch: {
    is_disabled() {
      if (this.is_disabled) this.unregisterAcceptButton();
      else this.reregisterAcceptButton();
    },
    loadFinish() {
      if (!this.loadFinish) return;
      setTimeout(() => this.hideLoadStatus = true, 5000);
      if (this.$store.state.autostart_mode) this.clickStart();
    },
  },
  mounted() {
    setTimeout(() => this.hideLoadStatus = false, 1);
    this.reregisterAcceptButton();
    this.timer_id = setInterval(() => {
      this.current_time = new FixDate();
    }, 1000);
  },
  unmounted() {
    if (this.timer_id != null) clearInterval(this.timer_id);
    this.timer_id = null;

    this.unregisterAcceptButton();
  },
  computed: {
    getTime() {
      return this.current_time.formattedTime();
    },
    loadFinish() {
      return (
        this.getUserInfo != null &&
        this.getPagesStruct != null &&
        this.getPageTemplatesStruct != null &&
        this.getCriticalError == null
      );
    },
    getUserInfo() {
      return this.$store.state.user_obj;
    },
    getPagesStruct() {
      return this.$store.state.raw_pages_struct;
    },
    getPageTemplatesStruct() {
      return this.$store.state.page_templates_struct;
    },
    getCriticalError() {
      return this.$store.state.critical_error;
    },
    loadingText() {
      if (this.loadFinish) return 'Система готова к работе';
      if (this.getCriticalError != null) return this.getCriticalError.shortInfo + '\n' + this.getCriticalError.fullInfo;
      if (this.getUserInfo == null) return 'Запрос данных пользователя';
      if (this.getPagesStruct == null) return 'Запрос структуры отчетов';
      if (this.getPageTemplatesStruct == null) return 'Запрос шаблонных страниц';
      return '?';
    },
  },
  methods: {
    reregisterAcceptButton() {
      this.unregisterAcceptButton();
      window.acceptableElements.push(this.clickStart);
    },
    unregisterAcceptButton() {
      const index = window.acceptableElements.indexOf(this.clickStart);
      if (index > -1) {
        window.acceptableElements.splice(index, 1);
      }
    },
    clickStart() {
      if (!this.loadFinish) {
        return;
      }
      this.$emit('start_work_click');
    },
  },
};
</script>

<style lang="less">

.loading-group {
  opacity: 0;
  transition: opacity 1.3s;
  margin-bottom: @base-gap;

  &__enabled {
    opacity: 1;
  }
}

.block-page-watch {
  position: absolute;
  bottom: 113px;
  right: 103px;
  font-size: 75px;
  font-weight: 100;

  @media (max-width: @mobile-width) {
    right: 50%;
    transform: translate(50%, 0);
  }
}

.start-work-button {
  position: absolute;
  left: 50%;
  transform: scale(1.6) translate((-50% / 1.6), 0);
  transform-origin: bottom;
  bottom: 35px;
  opacity: 1;

  text-shadow: none;

  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity @slow-animation-speed, bottom @slow-animation-speed;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    opacity: 0;
    bottom: 500px;
  }

  @media (max-width: @tablet-width) {
    transform: scale(1.4) translate((-50% / 1.4), 0);
    transform-origin: bottom;
  }

  @media (max-width: @mobile-width) {
    transform: scale(1.2) translate((-50% / 1.2), 0);
    transform-origin: bottom;
  }
}

.block-wrap-screen {
  color: @block-screen-text-color;
  padding: @double-gap;
  padding-top: 0px;
  display: block;

  text-shadow: black 1px 2px 1px;

  z-index: 1000;
}

@media (max-height: 600px) {
  .hello-block {
    display: none;
  }
}

</style>
