<template>
  <AuthBase>
     <div class="login-frame content-box">
      <div class="login_frame_header_text">
        <LabelComponent class="login-frame__header"
                            label_type="h1"
                            :label_text="'Войдите в свой Личный Кабинет'"/>
      </div>
      <div class="flex-row" v-if="!this.$store.getters.iosDemoMode">
      <LabelComponent label_type="button/large/regular 14"
                            :label_text="'Нет аккаунта?'"/>

            <ButtonComponent :label="'ЗАРЕГИСТРИРОВАТЬСЯ'"
                            elementType="default"
                            @click="this.$router.replace({path: '/register/', query: this.$route.query});"/>
      </div>
          <LabelComponent class="login-frame__global-error"
                          v-show="this.global_error_text != null"
                          :label_text="this.global_error_text"/>
        <form class="login-frame__footer">
          <InputField class="login-frame__login-field"
                      @input_change="set_login"
                      :set_values="this.login"
                      name="username"
                      autocomplete="on"
                      :label_text="'Имя Пользователя (e-mail адрес)'"
                      :input_field_status="this.login_input_data.status"
                      :error_text="this.login_input_data.error_text"/>
          <InputField class="login-frame__login-field"
                      @input_change="set_password"
                      name="password"
                      autocomplete="on"
                      :label_text="'Пароль'"
                      :input_field_status="this.password_input_data.status"
                      :error_text="this.password_input_data.error_text"
                      type="password"/>
        </form>
        <div class="login-frame__actions" >
          <ButtonComponent class="login-frame__button1"
                          label_type="button/large/medium 18"
                          elementSize="default"
                          :elementInLoading="this.auth_requesting"
                          @click="this.loginUserInSystem()"
                          :label="'ВОЙТИ'"/>
          <ButtonComponent class="login-frame__button2"
                           elementSize="default"
                           elementType="default"
                           @click="this.$router.replace({path: '/reset_password', query: {login: this.login}});"
                           :label="'Забыли пароль?'"/>
          <ButtonComponent v-if="this.work_in_application()"
                           class="login-frame__button2"
                           elementType="default"
                           @click="this.clear_cache()"
                           :label="'Очистить кэш приложения'"/>
                          </div>
    </div>
        <PinPage v-if="this.usePinWindow/* || true*/"
                 :next_page="this.next_page"
                 :cryptUserDataSaveKey="this.cryptUserDataSaveKey"
                 :savedUserData="this.readApplicationUserData"/>
  </AuthBase>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import InputField from '@/units/forms/InputField';
import PinPage from './PinPage';
import AuthBase from './AuthBase';
import TFACodeField from './TFACodeField';

import {authRequest} from '@/assets/dataSources/auth/authRequest';
import tfaAuth from '@/assets/dataSources/auth/tfaAuth';

export default ({
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
    PinPage,
    AuthBase,
  },
  props: {
    next_page: {default: '/'},
  },
  data: ()=> ({
    global_error_text: undefined,
    auth_requesting: false,
    login_input_data: {
      status: undefined,
      error_text: undefined,
    },
    password_input_data: {
      status: undefined,
      error_text: undefined,
    },
    login: undefined,
    password: undefined,
    usePinWindow: false,
    readApplicationUserData: undefined,
    cryptUserDataSaveKey: 'cryptCurrentUser',
    tfa_code_symbols: 6,
  }),
  watch: {

  },
  mounted() {
    this.login = this.$route.query.login;

    window.acceptableElements.push(this.loginUserInSystem);

    if (!this.usePin) {
      return;
    }

    window.applicationCallback.setCryptCurrentUser = (status, key, value) => {
      this.readApplicationUserData = value;

      if (value != null && value !== '') {
        this.usePinWindow = true;
      }
    };

    window.sendMessageInApplication({
      type: 'get_saved_string',
      key: this.cryptUserDataSaveKey,
      callback: 'window.applicationCallback.setCryptCurrentUser',
    });
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.loginUserInSystem);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    usePin() {
      return window.WORK_IN_APPLICATION && window.hasFunctions([
        'save_string',
        'get_saved_string',
        'delete_saved_string',
      ]);
    },
  },
  methods: {
    work_in_application() {
      return window.WORK_IN_APPLICATION;
    },
    clear_cache() {
      window.sendMessageInApplication({
        type: 'clear_cache',
        callback: 'window.applicationCallback.cacheCleared',
      });
    },
    set_login(data) {
      this.login=data;
    },
    set_password(data) {
      this.password=data;
    },
    nextUserLogin() {
      if (this.usePin) {
        this.usePinWindow = true;
      } else {
        // this.$store.commit('is_user_login_now');
        window.location.href = this.next_page ? this.next_page : '/';
        // this.$router.replace({path: this.next_page});
      }
    },
    async tfaAuth(tfaSession, tfaError) {
      this.auth_requesting = false;
      const tfaCode = await window.openWindow({
        caption: 'Двухфакторная аутентификация',
        component: TFACodeField,
        componentProps: {
          error_message: tfaError,
          tfa_code_symbol_count: this.tfa_code_symbols,
        },
      });

      if (tfaCode == null) return;

      this.auth_requesting = true;

      tfaAuth((response) => {
        if (response.status == 200) {
          this.nextUserLogin();
          return;
        }
        this.auth_requesting = false;
        this.tfaAuth(tfaSession, 'Неправильный код!');
      }, tfaSession, tfaCode).finally(() => (this.auth_requesting = false));
    },
    loginUserInSystem() {
      if (this.auth_requesting) {
        return;
      }

      this.global_error_text = null;

      const errorText = 'Поле должно быть заполнено';
      this.login_input_data.status = !this.login ? 'error' : undefined;
      this.login_input_data.error_text = !this.login ? errorText : undefined;
      this.password_input_data.status = !this.password ? 'error' : undefined;
      this.password_input_data.error_text = !this.password ? errorText : undefined;
      if (!this.login || !this.password) return;

      this.auth_requesting = true;

      authRequest(async (data) => {
        this.auth_requesting = false;
        if (data._error) {
          if (data.status == null) {
            this.global_error_text = `${data.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else {
            this.global_error_text = `${data.text} Статус: ${data.status}`;
          }

          return;
        }

        const tfaHeader = 'req_tfa|';
        if (String(data.auth_result).startsWith(tfaHeader)) {
          const tfaSession = data.auth_result.substring(tfaHeader.length);
          this.tfaAuth(tfaSession, null);
          return;
        }

        this.nextUserLogin();
      }, {
        login: this.login,
        password: this.password,
      });
    },
  },
});
</script>

<style lang="less">
.login_frame_header_text{
    min-width: 200px;
}
.login-frame {
  .flex(column, center, center);
  max-width: 390px;
  max-height: 100%;
  box-shadow: 0 0px 15px #505050;
  padding: 20px;

  &__header {
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__global-error {
    color: red;
    text-align: center;
  }

  &__login-field {
    margin: 11px 0;
  }
  &__button1 {
    width: 75%;
    max-width: none;
    margin-top: 10px;
  }
  &__button2 {
    margin-top: 20px;
    color: hsl(0, 100%, 55%);
  }

  &__footer{
    width: 80%;
    overflow-y: auto;
  }

  &__actions {
    width: 100%;
    .flex(column, center, center);
  }
}
.left_text_login{
  width: 277px;

}
.header_text_registration{
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    width: 316px;
    vertical-align: middle;
    margin-bottom: 10px;
  }
.content_login_page{    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;}
</style>
