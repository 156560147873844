<template>
  <BooleanZeroField :baseField="this.baseField" :withoutTitle="true"/>
</template>

<script>
import BooleanZeroField from '../BooleanZeroField.vue';

export default {
  components: {
    BooleanZeroField,
  },
  props: [
    'baseField',
    'withoutTitle',
  ],
};
</script>
