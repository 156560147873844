<template>
  <div v-show="!this.baseField.isHidden" class="micro-gap-column">
    <LabelComponent :label_text="this.baseField.errorText" v-if="this.baseField.errorText" class="error-text"/>
    <div class="base-gap-row row-content-up">
      <div class="micro-gap-row" v-if="!this.withoutTitle">
        <LabelComponent label_type="h4" :label_text="this.baseField.view"/>
        <LabelComponent label_text="*" v-if="this.baseField.required" class="error-text"/>
        <LabelComponent label_text=":"/>
      </div>
      <component :is="this.objSelector.component" v-bind="this.objSelector.params"/>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';

export default {
  components: {
    LabelComponent,
  },
  data: () => ({

  }),
  props: [
    'baseField',
    'withoutTitle',
  ],
  computed: {
    objSelector() {
      return this.baseField.renderObjSelector;
    },
  },
  mounted() {

  },
  methods: {

  },
};
</script>
