import LabelZeroField from './fields/LabelZeroField.js';
import NumberZeroField from './fields/NumberZeroField.js';
import TextZeroField from './fields/TextZeroField.js';
import BooleanZeroField from './fields/BooleanZeroField.js';
import DateZeroField from './fields/DateZeroField.js';
import ExTableZeroField from './fields/ExTableZeroField.js';
import ObjSelectZeroField from './fields/ObjSelectZeroField.js';


const fieldsDict = {
  'label': LabelZeroField,
  'number': NumberZeroField,
  'text': TextZeroField,
  'boolean': BooleanZeroField,
  'date': DateZeroField,
  'ex_table': ExTableZeroField,
  'entity_selector': ObjSelectZeroField,
};


function zeroFieldFactory(fieldIdx, fieldData, parent) {
  const CurField = fieldsDict[fieldData.type];

  if (CurField == null) {
    console.warn('For field data not found field!', fieldData);
    return null;
  }

  return new CurField(fieldIdx, fieldData, parent);
}


export default zeroFieldFactory;
