import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import ConnectSubscription from '@/subscriptionLayout/ConnectSubscription.vue';
import AcceptAccount from '@/baseLayout/auth/AcceptAccount.vue';

import IndexPage from '@/baseLayout/IndexPage.vue';
import Page404 from '@/baseLayout/PageNotFound.vue';
import LoginPage from '@/baseLayout/auth/LoginPage';
import RegistrationPage from '@/baseLayout/auth/RegistrationPage';
import ResetPassword from '@/baseLayout/auth/ResetPassword';
import ProfilePage from '@/baseLayout/auth/ProfilePage';

import SubscriptionPage from '@/subscriptionLayout/SubscriptionPage.vue';

import EntityPreview from '@/entitiesLayout/EntityPreview.vue';
import ReportPreview from '@/reportsLayout/ReportPreview.vue';
import FileStoragePreview from '@/fileStorageLayout/FileStoragePreview.vue';
import ReportPage from '@/reportsLayout/ReportPage.vue';
import FileStoragePage from '@/fileStorageLayout/FileStoragePage.vue';


import PageGroup from '@/navigationLayout/PageGroup.vue';


const routes = [
  {
    path: '/accept_account/:acceptAccountCode',
    name: 'AcceptAccount',
    component: AcceptAccount,
    meta: {
      viewName: 'Подтверждение аккаунта',
      // witoutBackground: true,
      noBasePage: true,
    },
  },
  {
    path: '/connect_subscription/:subscriptionCode',
    name: 'ConnectSubscription',
    component: ConnectSubscription,
    meta: {
      viewName: 'Подключение к подписке',
      witoutBackground: true,
    },
  },
  {
    path: '/entity_preview/:sourceUid/:entityType',
    name: 'EntityPreview',
    component: EntityPreview,
    meta: {
      viewName: 'Предпросмотр объекта',
    },
  },
  {
    path: '/report_preview/:sourceUid/:reportUid',
    name: 'ReportPreview',
    component: ReportPreview,
    meta: {
      viewName: 'Предпросмотр отчета',
    },
  },
  {
    path: '/storage_preview/:sourceUid/:storageUid',
    name: 'FileStoragePreview',
    component: FileStoragePreview,
    meta: {
      viewName: 'Предпросмотр Хранилища файлов',
    },
  },
  {
    path: '/',
    name: 'IndexPage',
    component: IndexPage,
    meta: {
      viewName: 'Добро пожаловать!',
      witoutBackground: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: (route) => {
      return {next_page: route.query.next};
    },
    meta: {
      noBasePage: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegistrationPage,
    meta: {
      noBasePage: true,
    },
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      noBasePage: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page404',
    component: Page404,
    props: (route) => ({
      path: route.href,
    }),
    meta: {
      viewName: 'Ошибка 404. Данная страница не найдена',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.isStructurePage) store.commit('current_page_info_mut', null);

  if (store.getters.isLoggedIn && (to.name == 'Login' || to.name == 'Register' || to.name == 'ResetPassword')) {
    // Если человек уже аутентифицирован, то ему не доступна страница логина/регистрации/сброса пароля
    next({path: '/'});
    return;
  }

  next();
});


const pageComponents = {
  'group': PageGroup,
  'subscription': SubscriptionPage,
  'profile': ProfilePage,
  'entity': null,
  'report': ReportPage,
  'storage': FileStoragePage,
  // 'admin': null,
};


let dynamicRoutes = [];


function addSitePagesRoutes() {
  const allPages = store.state.web_site_struct.filter((item) => (item.page_type !== 'ext_link'));

  // console.log('allPages', allPages);

  dynamicRoutes.forEach((item) => router.removeRoute(item.name));
  dynamicRoutes = [];

  dynamicRoutes = allPages.map((item) => ({
    path: item.fullUrl,
    name: item.fullUrl,
    component: pageComponents[item.page_type],
    beforeEnter(to, from, next) {
      store.commit('current_page_info_mut', item);
      document.title = item.name;
      next();
    },
    meta: {
      viewName: item.name,
      witoutBackground: pageComponents[item.page_type] ? pageComponents[item.page_type].witoutBackground : false,
      isStructurePage: true,
    },
  }));

  dynamicRoutes.forEach((item) => router.addRoute(item));
}

store.subscribe((mutation, state) => {
  if (!['user_obj_mut', 'web_site_struct_mut', 'page_templates_struct_mut'].includes(mutation.type)) return;
  addSitePagesRoutes();
  router.replace(router.currentRoute.value.fullPath);
});


export default router;
