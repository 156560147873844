<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="form-painter">
    <LabelComponent style="color: red;" v-if="this.global_error_text != null" :label_text="this.global_error_text" />
    <!-- <div class="meta-actions-container">
      <div v-for="(action, index) in this.getRawMetaActions" :key="index">
          <ButtonComponent :elementStatusModifier="action.system_name != 'delete' ? undefined : 'danger'"
                            :label="action.view"
                            @click="this.completeForm(action)"
          />
      </div>
    </div> -->
    <div :class="this.verticalMode ? 'simple-fields-container' : 'grid-fields-container'">
      <template v-for="field, fieldName in this.form_data" :key="fieldName">
        <template v-if="field.is_hidden"></template>
        <NavElemField v-else-if="field.itype == 'navelem'"
                  :label_text="field.label"
                  :initial_data="field.val"
                  :input_field_status="this.getFieldStatus(fieldName)"
                  :exclude_uid_list="field._orig_field.exclude_uids"
                  @input_change="(data) => this.setFormValue(fieldName, data)"
        />
        <IconField v-else-if="field.itype == 'icon'"
                  :label_text="field.label"
                  :initial_data="field.val"
                  :input_field_status="this.getFieldStatus(fieldName)"
                  @input_change="(data) => this.setFormValue(fieldName, data)"
        />
        <ForeignObject v-else-if="field.itype == 'link' || field.itype == 'link_list'"
                  :dsUid="field._orig_field.ds_uid"
                  :isMultiSelect="field.itype == 'link_list'"
                  :labelText="field.label"
                  :objectTypeView="field.link_object_view"
                  :objectType="field.link_object"
                  :fieldName="fieldName"
                  :selectedItems="field.val"
                  :inputFieldStatus="this.getFieldStatus(fieldName)"
                  :favoritableIdx="field.link_object"
                  @inputChange="(data) => this.setFormValue(fieldName, data)"
        />
        <DropDownInput v-else-if="field.itype == 'choice' || field.itype == 'choice_list'"
                      :extenderHandler="null"
                      :editorHandler="null"
                      :removeHandler="null"

                      :isFilterable="true"
                      :isMultiSelect="field.itype == 'choice_list'"

                      :itemList="field._orig_field.item_list"
                      :selectedItems="field._orig_field.selected_items"

                      :labelText="field.label"
                      :fieldStatus="this.getFieldStatus(fieldName)"
                      :errorText="field.error"
                      :isRequired="false"
                      :favoritableIdx="null"
                      @inputChange="(data) => this.setFormValue(fieldName, data)"
        />
        <CheckBox v-else-if="field.itype == 'boolean'"
            :is_toggle="true"
            :label_text_left="field.label"
            :init_value="field.val"
            :check_box_field_status="this.getFieldStatus(fieldName)"
            :error_text="field.error"
            @input_check="(data) => this.setFormValue(fieldName, data)"
            />
        <InlineFormField v-else-if="field.itype == 'table'"
                        :set_values="field.val"
                        :formInitStruct="field"
                        :hideLinkedField="true"
                        @input_change="(data) => this.setFormValue(fieldName, data)"
                        />
        <DateRange v-else-if="field.itype == 'dateperiod' || field.itype == 'period_date'"
                  :label_text="field.label"
                  :default_values="field.val"
                  @inputChanged="(data) => this.setFormValue(fieldName, data)"
        />
        <DateTimeInputField v-else-if="field.itype.includes('date')"
                        :title="field.label"
                        :set_values="field.val"
                        @input_change="(data) => this.setFormValue(fieldName, data)"
                        />
        <InputField v-else
                    :type="field.itype"
                    :label_text="field.label"
                    :input_field_status="this.getFieldStatus(fieldName)"
                    :error_text="field.error"
                    :set_values="field.val"
                    :block_edit="field._orig_field.readonly == true"
                    autocomplete="off"
                    @input_change="(data) => this.setFormValue(fieldName, data)"
                    :check_pattern="field.input_pattern ? field.input_pattern : '.+'"
                    :pattern_invalid_message="field.input_pattern_message"
                    />
      </template>
    </div>
    <div class="actions-container" :class="{'center-content': this.actionsAlign == 'center'}">
      <div v-for="(action, index) in this.getRawActions" :key="index">
          <ButtonComponent :elementStatusModifier="action.is_loading ? undefined : (action.system_name != 'delete' ? undefined : 'danger')"
                            :elementInLoading="action.is_loading"
                            :label="action.view"
                            @click="action.is_loading ? '' : this.completeForm(action)"
          />
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/units/forms/InputField.vue';
import DateRange from '@/units/forms/DateRange.vue';
import InlineFormField from '@/units/forms/InlineFormField';

import ButtonComponent from '@/units/forms/RichButton';

import LabelComponent from '@/units/RichLabel';

import ForeignObject from '@/units/forms/ForeignObject';
import DropDownInput from '@/units/forms/DropDownInput.vue';

import CheckBox from '@/units/forms/CheckBox';

import IconField from '@/units/forms/IconField.vue';
import NavElemField from '@/units/forms/NavElemField.vue';

import DateTimeInputField from '@/units/InputFields/DateTimeInputField.vue';

export default ({
  emits: [
    'completeFormAction',
    'completeFormRichAction',
  ],
  components: {
    ButtonComponent,
    InputField,
    ForeignObject,
    DropDownInput,
    InlineFormField,
    LabelComponent,
    CheckBox,
    DateRange,
    IconField,
    NavElemField,
    DateTimeInputField,
  },
  props: {
    raw_fields: {
      required: true,
      type: Array,
    },
    raw_actions: {
      required: true,
      type: Array,
    },
    actionsAlign: {
      required: false,
      default: 'left',
      type: String,
    },
    verticalMode: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    global_error_text: null,
    form_data: {
      // surname: {label: 'Last Name', error: null, val: null, pattern: '[a-zA-Zа-яА-Я]+'},
      // name: {label: 'First Name', error: null, val: null, pattern: '[a-zA-Zа-яА-Я]+'},
      // citizenship: {label: 'Citizenship', error: null, val: null},
      // country_of_residence: {label: 'Country of Residence', error: null, val: null, itype: 'text'},
      // email: {label: 'Email', error: null, val: null, itype: 'email'},
      // password1: {label: 'Password', error: null, val: null, itype: 'password'},
      // password2: {label: 'Password confirmation', error: null, val: null, itype: 'password'},
      // privacy_policy_agreed: {label: 'I agree with the privacy policy', error: null, val: false},
      // __action__: null
    },
  }),
  mounted() {
    this.initFromRaw();
  },
  watch: {
    raw_fields() {
      this.initFromRaw();
    },
  },
  computed: {
    getRawActions() {
      return this.raw_actions.filter((item) => !item.is_meta_action);
    },
    getRawMetaActions() {
      return this.raw_actions.filter((item) => item.is_meta_action);
    },
  },

  methods: {
    initFromRaw() {
      // console.log(this.raw_fields, this.form_data);

      if (this.raw_fields == null) {
        return;
      }

      // console.log('this.form_data', this.form_data, this.raw_fields);

      this.form_data = {};

      this.raw_fields.forEach((field) => {
        // child_set: {
        //   label: 'Children',
        //   error: null,
        //   val: null,
        //   itype: 'inline',
        //   content_form: {
        //     children_age: {label: 'Child age', error: null, val: null, itype: 'number'},
        //     children_gender: {label: 'Child gender', error: null, val: null, itype: 'dropdown', ddVals: []},
        //     children_live_together: {label: 'Do your children live with you?', error: null, val: false, itype: 'boolean'},
        //   },
        // },

        this.form_data[field.system_name] = {
          label: field.view,
          error: null,
          val: field.value,
          itype: field.type,
          _orig_field: field,
          required: field.nullable !== true,
          readonly: field.readonly === true,
          is_hidden: field.is_hidden === true,
          link_object: field.linked_object_type,
          link_object_view: field.view,
          input_pattern: field.pattern,
          input_pattern_message: field.pattern_message ? field.pattern_message : '',
        };

        if (field.type == 'link') {
          this.form_data[field.system_name].val = field.value == null ? [] : [field.value];
        }

        if (field.type == 'dateperiod' || field.type == 'period_date') {
          this.form_data[field.system_name].val = field.value == null ? null : {
            'from': new FixDate(field.value.start_date),
            'to': new FixDate(field.value.end_date),
          };
        }

        if (field.type == 'table') {
          const tableForm = {};

          field.linked_object_form.fields.forEach((subField) => {
            tableForm[subField.system_name] = {
              label: subField.view,
              error: null,
              val: null,
              _orig_field: subField,
              itype: subField.type,
              required: subField.nullable !== true,
              readonly: subField.readonly === true,
              is_hidden: subField.is_hidden === true,
              link_object: subField.linked_object_type,
              link_object_view: subField.view,
              input_pattern: subField.pattern,
              input_pattern_message: subField.pattern_message ? subField.pattern_message : '',
            };
          });

          this.form_data[field.system_name].val = field.value.map((rawVal) => Object.fromEntries(Object.keys(rawVal.values).map((fieldKey) => [
            fieldKey,
            {
              label: tableForm[fieldKey].label,
              error: null,
              val: tableForm[fieldKey].itype == 'link' ? (rawVal.values[fieldKey] == null ? [] : [rawVal.values[fieldKey]]) : rawVal.values[fieldKey],
              itype: tableForm[fieldKey].itype,
              _orig_field: tableForm[fieldKey]._orig_field,
              required: tableForm[fieldKey].required,
              readonly: tableForm[fieldKey].readonly,
              is_hidden: tableForm[fieldKey].is_hidden,
              link_object: tableForm[fieldKey].link_object,
              link_object_view: tableForm[fieldKey].link_object_view,
              input_pattern: tableForm[fieldKey].input_pattern,
              input_pattern_message: tableForm[fieldKey].input_pattern_message,
            },
          ])));

          this.form_data[field.system_name]['linked_field_name'] = field.linked_field_name;
          this.form_data[field.system_name]['content_form'] = tableForm;
        }
      });

      // console.log('this.form_dataaaaaaaa', this.form_data);

      // this.form_data = {};
    },
    setFormValue(field, value) {
      this.global_error_text = null;
      this.form_data[field].val = value;
      this.form_data[field].error = null;
    },
    getFieldStatus(field) {
      return this.form_data[field].error != null ? 'error' : undefined;
    },
    completeForm(rawAction) {
      // this.form_data.__action__ = rawAction;

      let isError = false;
      const fillCorrectText = 'Проверьте правильность заполнения данных!';

      Object.keys(this.form_data).forEach((key) => {
        if (this.form_data[key].required == true && (!this.form_data[key].val || this.form_data[key].val.length < 1)) {
          this.form_data[key].error = 'Поле должно быть заполнено';
          isError = true;
        }
      });

      if (isError) {
        this.global_error_text = fillCorrectText;
        return;
      }

      // console.log('confirmed', this.form_data, rawAction);

      function fillPostForm(fillForm, translateVal, valType, basedKey) {
        if (translateVal == null) {
          return;
        }

        if (valType == 'table') {
          fillForm.append(basedKey + '-TOTAL_FORMS', translateVal.length);
          // fillForm.append(basedKey + '-INITIAL_FORMS', 0);
          translateVal.forEach((elem, idx) => {
            Object.keys(elem).forEach((inlineKey) => {
              fillPostForm(
                  fillForm,
                  elem[inlineKey].val,
                  elem[inlineKey].itype,
                  basedKey + '-' + idx + '-' + inlineKey,
              );
            });
          });
          return;
        }

        if (valType == 'dateperiod' || valType == 'period_date') {
          let ans = '';

          if (translateVal.from instanceof FixDate) {
            ans += translateVal.from.toISO();
          } else {
            ans += String(translateVal.from);
          }

          ans += '__';

          if (translateVal.to instanceof FixDate) {
            ans += translateVal.to.toISO();
          } else {
            ans += String(translateVal.to);
          }

          fillForm.append(basedKey, ans);
          return;
        }

        if (Array.isArray(translateVal)) {
          translateVal.forEach((elem) => (elem.uid !== undefined ? fillForm.append(basedKey, elem.uid) : fillForm.append(basedKey, elem)));
          return;
        }

        if (translateVal instanceof FixDate) {
          fillForm.append(basedKey, translateVal.toISO());
          return;
        }

        if (translateVal === true) {
          fillForm.append(basedKey, 1);
          return;
        }

        if (translateVal === false) {
          fillForm.append(basedKey, 0);
          return;
        }

        if (translateVal.uid !== undefined) fillForm.append(basedKey, translateVal.uid);
        else fillForm.append(basedKey, translateVal);
      }

      const postForm = new FormData();
      Object.keys(this.form_data).forEach((key) => {
        fillPostForm(postForm, this.form_data[key].val, this.form_data[key].itype, key);
      });

      postForm.append('__action__', rawAction.system_name);

      this.$emit('completeFormAction', postForm);

      function fillJsonForm(fillForm, translateVal, valType, basedKey) {
        if (translateVal == null) {
          return;
        }

        // if (valType == 'table') {
        //   fillForm.append(basedKey + '-TOTAL_FORMS', translateVal.length);
        //   // fillForm.append(basedKey + '-INITIAL_FORMS', 0);
        //   translateVal.forEach((elem, idx) => {
        //     Object.keys(elem).forEach((inlineKey) => {
        //       fillPostForm(
        //           fillForm,
        //           elem[inlineKey].val,
        //           elem[inlineKey].itype,
        //           basedKey + '-' + idx + '-' + inlineKey,
        //       );
        //     });
        //   });
        //   return;
        // }

        if (valType == 'dateperiod' || valType == 'period_date') {
          const ans = {};

          if (translateVal.from instanceof FixDate) {
            ans.start_date = translateVal.from.toISO();
          } else {
            ans.start_date = String(translateVal.from);
          }

          if (translateVal.to instanceof FixDate) {
            ans.end_date = translateVal.to.toISO();
          } else {
            ans.end_date = String(translateVal.to);
          }

          fillForm[basedKey] = ans;

          // fillForm.append(basedKey, JSON.stringify(ans));
          return;
        }

        if (Array.isArray(translateVal)) {
          fillForm[basedKey] = translateVal.map((elem) => elem.uid !== undefined ? elem : {uid: elem});
          if (valType != 'table' && valType != 'link_list') fillForm[basedKey] = fillForm[basedKey][0];
          return;
        }

        if (translateVal instanceof FixDate) {
          fillForm[basedKey] = translateVal.toISO();
          return;
        }

        if (translateVal === true) {
          fillForm[basedKey] = true;
          return;
        }

        if (translateVal === false) {
          fillForm[basedKey] = false;
          return;
        }

        if (translateVal.uid !== undefined) fillForm[basedKey] = translateVal;
        else fillForm[basedKey] = translateVal;
      }

      const jsonForm = {};
      Object.keys(this.form_data).forEach((key) => {
        fillJsonForm(jsonForm, this.form_data[key].val, this.form_data[key].itype, key);
      });
      jsonForm['__action__'] = rawAction;

      this.$emit('completeFormRichAction', jsonForm);
    },
  },
});
</script>

<style lang="less">
.center-content {
  justify-content: center;
}

.form-painter {
  & > * {
    margin-bottom: 13px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }

  .meta-actions-container {
    display: flex;
    gap: 10px;
  }

  .grid-fields-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 13px;

    @media screen and (max-width: @big-screen-width) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: @desktop-width) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: @mobile-width) {
      grid-template-columns: 1fr;
    }
  }

  .simple-fields-container > *{
    margin-bottom: 13px;
  }

  .actions-container {
    display: flex;
    gap: 10px;
  }
}
</style>
