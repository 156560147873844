<template>
  <DateInput
              @dateChange="this.setNewDate"
              v-show="!this.baseField.isHidden"
              :base_day="this.day"
              :base_month="this.month"
              :base_year="this.year"
  />
</template>

<script>
import DateInput from '../../units/DateInput.vue';

export default {
  components: {
    DateInput,
  },
  data: () => ({

  }),
  props: [
    'baseField',
  ],
  computed: {
    day() {
      return this.baseField.value == null ? null : this.baseField.value.day;
    },
    month() {
      return this.baseField.value == null ? null : this.baseField.value.month;
    },
    year() {
      return this.baseField.value == null ? null : this.baseField.value.year;
    },
  },
  mounted() {

  },
  methods: {
    setNewDate(event) {
      if (event == null) {
        this.baseField.setValue(null);
      } else {
        this.baseField.setValue(new FixDate(event.year, event.month, event.day));
      }
    },
  },
};
</script>
