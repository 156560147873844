<template>
    <div v-show="!this.baseField.isHidden" class="bool-field micro-gap-column" :class="{'bool-field-inactive': this.baseField.disabled}" @click="this.changeValue">
      <LabelComponent label_text="this.baseField.errorText" v-if="this.baseField.errorText" class="error-text"/>
      <div class="base-gap-row row-content-up">
        <div class="micro-gap-row" v-if="!this.withoutTitle">
          <LabelComponent label_type="h4" :label_text="this.baseField.view"/>
          <LabelComponent label_text="*" v-if="this.baseField.required" class="error-text"/>
          <LabelComponent label_text=":"/>
        </div>
        <div v-if="!this.baseField.isCheckbox" class="bool-toggle">
          <div class="bool-toggle-front" :class="{'bool-toggle-front-active': this.baseField.value == true}"/>
        </div>
        <div v-else class="bool-checkbox" :class="{'bool-checkbox-active': this.baseField.value == true}"></div>
      </div>
    </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';

export default {
  components: {
    LabelComponent,
  },
  data: () => ({

  }),
  props: [
    'baseField',
    'withoutTitle',
    // 'validator',
    // 'changeHandler',
  ],
  computed: {
  },
  mounted() {

  },
  methods: {
    changeValue() {
      if (this.baseField.disabled) return;
      this.baseField.setValue(this.baseField.value == false);
    },
  },
};
</script>

<style lang="less">
@toogle-size: 24px;

.bool-field {
  cursor: pointer;
  &-inactive {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.bool-toggle {
  width: @toogle-size * 2;
  height: @toogle-size;
  border-radius: (@toogle-size / 2);
  background: @scrollbar-background-color;

  // &-inactive {
  //   opacity: 0.6;
  //   cursor: not-allowed;
  // }

  &-front {
    width: @toogle-size;
    height: @toogle-size;
    border-radius: (@toogle-size / 2);
    background: @no-accent-text-color;
    margin-left: 0;
    transition: margin @animation-speed, background @animation-speed;

    &-active{
      margin-left: @toogle-size;
      background-color: @accent-text-color;
    }
  }
}

.bool-checkbox {
  width: @toogle-size;
  height: @toogle-size;
  border: 1px solid @content-background-border-color;
  border-radius: @toogle-size / 4;
  background: @content-background-color;
  transition: background @animation-speed;

  &-active {
    background: @accent-text-color;
  }
}
</style>
