<template>
  <InternalPageNode :levelData="[{'name': this.only_templates ? '<Пусто>' : '<Корень>', 'page_type': 'group'}]"
               :currentDepth="0"
               @select_elem="this.selectElem"
  />

  <InternalPageNode :levelData="this.getPagesStruct"
               :currentDepth="0"
               :exclude_uid_list="this.exclude_uid_list"
               :only_templates="this.only_templates"
               @select_elem="this.selectElem"
  />
</template>

<script type="text/javascript">
import InternalPageNode from './InternalPageNode.vue';

export default {
  components: {
    InternalPageNode,
  },
  data: () => ({

  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    selectElem(elem) {
      if (elem.uid == null) elem = null;
      this.$emit('result', elem);
    },
  },
  computed: {
    getPagesStruct() {
      return this.params.baseField.objContainer.drawObjects;
    },
    exclude_uid_list() {
      return this.params.baseField.objContainer.excludeUids;
    },
    only_templates() {
      return this.params.baseField.objContainer.useTypes == 'page_template';
    },
  },
};
</script>
