<template>
    <ButtonComponent :label="this.baseAction.view" @click="this.callAction"/>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    ButtonComponent,
  },
  props: [
    'baseAction',
  ],
  methods: {
    callAction() {
      this.baseAction.call();
    },
  },
};
</script>
